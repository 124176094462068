import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  ReducerAction,
  useReducer,
} from "react";
import { useRouter } from "next/router";
import { useWindowStorageDeprecated } from "utils/hooks/useWindowStorage";
import { Step, UserAnswers } from "types/contentful";
import { ContentfulQuizModelHoisted } from "types/contentful";
import { ReadingSkillJsonItem } from "types/contentful";

const initialState: GuidedSignUpProviderState = {
  contentfulQuizModel: {} as ContentfulQuizModelHoisted,
  readingSkills: [],
  readingLevel: null,
  quizSlug: null,
  quizVersion: null,
  step: null,
  steps: [],
  userAnswers: {} as UserAnswers,
  userPath: null,
};

enum ActionType {
  DECREMENT_STEP = "DECREMENT_STEP",
  HYDRATE_STATE = "HYDRATE_STATE",
  INCREMENT_STEP = "INCREMENT_STEP",
  SET_CONTENTFUL_QUIZ_MODEL = "SET_CONTENTFUL_QUIZ_MODEL",
  SET_READING_LEVEL = "SET_READING_LEVEL",
  SET_STEPS = "SET_STEPS",
  UPDATE_USER_ANSWERS = "UPDATE_USER_ANSWERS",
}

export interface GuidedSignUpProviderState {
  userPath: number[] | null;
  steps: Step[];
  stepIndex?: number;
  contentfulQuizModel?: ContentfulQuizModelHoisted;
  readingLevel?: ReadingSkillJsonItem | null;
  readingSkills: ReadingSkillJsonItem[];
  quizSlug?: string | null;
  quizVersion?: number | null;
  step?: Step | null;
  userAnswers?: UserAnswers;
  decrementStep?: () => void;
  hydrateState?: (state: Partial<GuidedSignUpProviderState>) => void;
  incrementStep?: (stepIndex: number) => void;
  setContentfulQuizModel?: (
    contentfulQuizModel: ContentfulQuizModelHoisted
  ) => void;
  setReadingLevel?: Dispatch<ReadingSkillJsonItem>;
  setSteps?: (steps: Step[]) => void;
  storage?: GuidedSignUpStorage;
  storageReady?: boolean;
  updateUserAnswers?: ({ answers }: { answers?: UserAnswers }) => void;
}

export interface GuidedSignUpStorage {
  contentfulQuizModel?: GuidedSignUpProviderState["contentfulQuizModel"];
  readingSkills?: GuidedSignUpProviderState["readingSkills"];
  readingLevel?: GuidedSignUpProviderState["readingLevel"];
  quizSlug?: GuidedSignUpProviderState["quizSlug"];
  quizVersion?: GuidedSignUpProviderState["quizVersion"];
  step?: GuidedSignUpProviderState["step"];
  steps?: GuidedSignUpProviderState["steps"];
  userAnswers?: GuidedSignUpProviderState["userAnswers"];
  userPath?: GuidedSignUpProviderState["userPath"];
}

type SetContextInStorage = (
  newState: Partial<GuidedSignUpProviderState>
) => void;

export type GuidedSignUpProviderReducerAction = {
  type: ActionType;
  setContextInStorage?: SetContextInStorage;
  state?: Partial<GuidedSignUpProviderState>;
  answers?: GuidedSignUpProviderState["userAnswers"];
} & Partial<GuidedSignUpProviderState>;

/**
 * Context reducer.
 *
 * @note - We update localStorage as part of a subset of operations
 *         at the same time as updating context.
 */
const reducer: Reducer<
  GuidedSignUpProviderState,
  GuidedSignUpProviderReducerAction
> = (state, action) => {
  switch (action.type) {
    case ActionType.DECREMENT_STEP: {
      const userPath = state.userPath?.slice(0, -1) ?? [];
      const stepIndex = userPath?.[userPath.length - 1];
      const newState = {
        ...state,
        userPath,
        step: state.steps[stepIndex as number],
      };

      action.setContextInStorage?.(newState);
      return newState;
    }
    case ActionType.HYDRATE_STATE: {
      const newState = {
        ...state,
        ...action.state,
      };

      action.setContextInStorage?.(newState);
      return newState;
    }
    case ActionType.INCREMENT_STEP: {
      const { stepIndex, setContextInStorage } = action;
      const newState = {
        ...state,
        userPath: [...(state.userPath ?? []), stepIndex as number],
        step: state.steps[stepIndex as number],
      };

      setContextInStorage?.(newState);
      return newState;
    }
    case ActionType.SET_CONTENTFUL_QUIZ_MODEL: {
      const { contentfulQuizModel } = action;

      return {
        ...state,
        contentfulQuizModel,
        quizSlug: contentfulQuizModel?.slug,
        quizVersion: contentfulQuizModel?.version,
        readingSkills: (contentfulQuizModel?.readingSkills?.json ||
          []) as ReadingSkillJsonItem[],
      };
    }
    case ActionType.SET_READING_LEVEL: {
      const { readingLevel } = action;
      return {
        ...state,
        readingLevel,
      };
    }
    case ActionType.SET_STEPS: {
      return {
        ...state,
        step: (state?.step || action.steps?.[0]) as Step,
        steps: action.steps as Step[],
        userPath: state.userPath || [0],
      };
    }
    case ActionType.UPDATE_USER_ANSWERS: {
      const { answers, setContextInStorage } = action;
      const newState = {
        ...state,
        userAnswers: {
          ...state.userAnswers,
          ...answers,
        } as UserAnswers,
      };

      setContextInStorage?.(newState);
      return newState;
    }
    default:
      return state;
  }
};

type GuidedSignUpProviderDispatch = Dispatch<ReducerAction<typeof reducer>>;

/**
 * Action for setting the contentful model.
 */
const decrementStep =
  (
    dispatch: GuidedSignUpProviderDispatch,
    setContextInStorage: SetContextInStorage
  ) =>
  () => {
    dispatch({
      type: ActionType.DECREMENT_STEP,
      setContextInStorage,
    });
  };

/**
 * Action for hydrating state in bulk.
 *
 * @note - main use case for seeding data from previous sessions
 */
const hydrateState =
  (
    dispatch: GuidedSignUpProviderDispatch,
    setContextInStorage: SetContextInStorage
  ) =>
  (state: Partial<GuidedSignUpProviderState>) => {
    dispatch({
      type: ActionType.HYDRATE_STATE,
      setContextInStorage,
      state,
    });
  };

/**
 * Action for setting the contentful model.
 */
const incrementStep =
  (
    dispatch: GuidedSignUpProviderDispatch,
    setContextInStorage: SetContextInStorage
  ) =>
  (stepIndex: number) => {
    if (!stepIndex) {
      console.error(
        "setContenfulModel: `model` was not provided to the provider"
      );
      return;
    }

    dispatch({
      type: ActionType.INCREMENT_STEP,
      setContextInStorage,
      stepIndex,
    });
  };

/**
 * Action for setting the contentful model.
 */
const setContentfulQuizModel =
  (dispatch: GuidedSignUpProviderDispatch) =>
  (contentfulQuizModel: ContentfulQuizModelHoisted) => {
    if (!contentfulQuizModel) {
      console.error(
        "setContenfulModel: `contentfulQuizModel` was not provided to the provider"
      );
      return;
    }

    dispatch({
      type: ActionType.SET_CONTENTFUL_QUIZ_MODEL,
      contentfulQuizModel,
    });
  };

/**
 * Action for setting the derived reading level based upon completion of the quiz.
 */
const setReadingLevel =
  (dispatch: GuidedSignUpProviderDispatch) =>
  (readingLevel: ReadingSkillJsonItem) => {
    if (!readingLevel) {
      console.error(
        "setReadingLevel: `readingLevel` was not provided to the provider"
      );
      return;
    }

    dispatch({
      type: ActionType.SET_READING_LEVEL,
      readingLevel,
    });
  };

/**
 * Action for setting the steps (and substeps).
 */
const setSteps =
  (dispatch: GuidedSignUpProviderDispatch) => (steps: Step[]) => {
    if (!steps) {
      console.error("setSteps: `steps` was not provided to the provider");
      return;
    }

    dispatch({
      type: ActionType.SET_STEPS,
      steps,
    });
  };

/**
 * Action for updating user quiz answers.
 */
const updateUserAnswers =
  (
    dispatch: GuidedSignUpProviderDispatch,
    setContextInStorage: SetContextInStorage
  ) =>
  ({ answers }: { answers?: UserAnswers }) => {
    if (!answers) {
      console.error(
        "updateUserAnswers: `answers` were not provided to the provider"
      );
      return;
    }

    dispatch({
      type: ActionType.UPDATE_USER_ANSWERS,
      answers,
      setContextInStorage,
    });
  };

/**
 * Used to dynamically create local storage keys for storing user answers.
 */
export const getStorageKey = ({
  contentfulQuizModelSlug,
  subId,
}: {
  contentfulQuizModelSlug?: string;
  subId?: string;
}) => {
  return contentfulQuizModelSlug
    ? `${contentfulQuizModelSlug}${subId ? `-${subId}` : ""}`
    : null;
};

export const GuidedSignUpContext =
  createContext<GuidedSignUpProviderState>(initialState);

export interface GuidedSignUpProviderProps {
  children?: ReactNode;
}

/**
 * Global state for the guided sign up flow.
 *
 * @note - We will keep this context as lean as possible with only
 *         simple getters and setters. App logic should live in hooks
 *         or components consuming this context.
 */
const GuidedSignUpProvider = ({ children }: GuidedSignUpProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();

  // keep localStorage in sync with updates
  const [storageReady, { getItem, setItem }] = useWindowStorageDeprecated();
  const storageKey = getStorageKey({
    contentfulQuizModelSlug: state?.contentfulQuizModel?.slug,
    subId: router?.query?.id as string,
  });

  const setContextInStorage = (state: Partial<GuidedSignUpProviderState>) => {
    if (storageReady && storageKey) {
      setItem(storageKey, { ...state });
    }
  };

  const value: GuidedSignUpProviderState = {
    decrementStep: decrementStep(dispatch, setContextInStorage),
    hydrateState: hydrateState(dispatch, setContextInStorage),
    incrementStep: incrementStep(dispatch, setContextInStorage),
    setContentfulQuizModel: setContentfulQuizModel(dispatch),
    setReadingLevel: setReadingLevel(dispatch),
    setSteps: setSteps(dispatch),
    storage:
      storageReady && storageKey
        ? (getItem(storageKey) as GuidedSignUpStorage)
        : undefined,
    storageReady,
    updateUserAnswers: updateUserAnswers(dispatch, setContextInStorage),
    ...state,
  };

  return (
    <GuidedSignUpContext.Provider value={value}>
      {children}
    </GuidedSignUpContext.Provider>
  );
};

export default GuidedSignUpProvider;
