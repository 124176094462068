import React, { ReactNode } from "react";
import styled from "styled-components";
import {
  Icon,
  IconName,
  Image,
  ImageProps,
  PageSection,
  PageSectionClassName,
  Theme,
} from "@literati/public-ui-shared-react";

/** This is the className that will be pre-pended to all of GuidedSignUpBoxShippingDetails's inner component classNames */
export const GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE =
  "guided-sign-up-box-shipping-details";

/** These are all of the internal classNames that are used within the GuidedSignUpBoxShippingDetails component */
export const GuidedSignUpBoxShippingDetailsClassName = {
  BASE: GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE,
  IMAGES_CONTAINER: `${GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE}-images-container`,
  IMAGE: `${GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE}-image`,
  SHIPPING_CONTAINER: `${GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE}-shipping-container`,
  ICON: `${GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE}-icon`,
  SHIPPING_COPY: `${GUIDED_SIGN_UP_BOX_SHIPPING_DETAILS_CLASS_BASE}-shipping-copy`,
} as const;

export interface GuidedSignUpBoxShippingDetailsProps {
  className?: string;
  heading?: ReactNode;
  images?: [ImageProps, ImageProps];
  shippingCopy?: ReactNode;
  subtext?: ReactNode;
}

const StyledPageSection = styled(PageSection)`
  padding: 2rem 0;

  .${PageSectionClassName.HEADING} {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: ${({ theme }) => theme.font.fontFamily.hoefler};
  }

  .${PageSectionClassName.CONTENT_SPACING_CHILDREN} {
    padding: 0;
  }

  .${PageSectionClassName.SUBTEXT} {
    font-size: 0.875rem;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 0;
`;

const ShippingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0;
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.colors.papierBlanc};
  border-radius: 6px;
  height: 4rem;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledShippingCopy = styled.p`
  font-size: 0.75rem;
  text-align: left;
  margin: 0 0 0 0.75rem;
`;

export const GuidedSignUpBoxShippingDetailsImagesDefaults: [
  ImageProps,
  ImageProps
] = [
  {
    src: "//images.ctfassets.net/vqtz4cq2w5ts/7cIH5OfRKTu1EPJFL24AYa/23d39cde970a3b479988357113d45e9d/KIDS_Ranger_Books_SM_Cropped.png",
    height: 153,
    width: 167,
  },
  {
    src: "//images.ctfassets.net/vqtz4cq2w5ts/1eru4iXctAyPFOERQ3M0vq/3c328e7bd0936aaa492f44dfaace390e/KIDS_Preschool_2ndgrade_Extras_SM_Cropped.png",
    height: 153,
    width: 167,
  },
];

export const GuidedSignUpBoxShippingDetails = ({
  className = "",
  heading = "What’s included?",
  images = GuidedSignUpBoxShippingDetailsImagesDefaults,
  shippingCopy = "Free shipping on returns. Skip or cancel anytime. No obligation to buy books.",
  subtext = "Each box contains 5 books chosen for your child, art by a children’s book illustrator, and a storytime activity!",
}: GuidedSignUpBoxShippingDetailsProps) => {
  return (
    <Theme>
      <StyledPageSection
        className={`${GuidedSignUpBoxShippingDetailsClassName.BASE} ${className}`}
        primaryHeading={heading}
        subtext={subtext}
      >
        <ImagesContainer
          className={GuidedSignUpBoxShippingDetailsClassName.IMAGES_CONTAINER}
        >
          {images.map((imageProps, idx) => (
            <Image
              className={GuidedSignUpBoxShippingDetailsClassName.IMAGE}
              {...imageProps}
              key={`guided-sign-up-box-shipping-details-image-${idx}`}
            />
          ))}
        </ImagesContainer>
        <ShippingContainer
          className={GuidedSignUpBoxShippingDetailsClassName.SHIPPING_CONTAINER}
        >
          <StyledIcon
            className={GuidedSignUpBoxShippingDetailsClassName.ICON}
            name={IconName.RAINBOW}
          />
          <StyledShippingCopy
            className={GuidedSignUpBoxShippingDetailsClassName.SHIPPING_COPY}
          >
            {shippingCopy}
          </StyledShippingCopy>
        </ShippingContainer>
      </StyledPageSection>
    </Theme>
  );
};

export default GuidedSignUpBoxShippingDetails;
