import PropTypes from "prop-types";
import styled from "styled-components";

const PillContainer = styled.div`
  background: #b3c9ff33;
  padding: 0.4rem 1rem;
  border-radius: 6.25rem;

  font-family: "Avenir";
  color: ${({ theme }) => theme.colors["perriwinkle-dark"]};
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;
  max-width: fit-content;
`;

/**
 * Looks like a thinner, highlighted GuidedSignUpPill, but it is not interactive
 * (it is only used for display purposes).
 *
 * @component
 * @param {Object} props
 * @param {String} props.className
 * @param {object} props.children
 */
function GuidedSignUpDisplayPill({ className, children }) {
  return <PillContainer className={className}>{children}</PillContainer>;
}

GuidedSignUpDisplayPill.defaultTypes = {
  children: null,
};

GuidedSignUpDisplayPill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default GuidedSignUpDisplayPill;
