import styled from "styled-components";
import {
  Formik,
  Form as FormikForm,
  FormikHelpers,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import { camelCase } from "lodash";
import { GuidedSignUpStepSlug } from "utils/hooks/useGuidedSignUp";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import GuidedSignUpPillCloud from "components/GuidedSignUp/GuidedSignUpPillCloud";
import GuidedSignUpLayoutReadingGrade, {
  getInitialValuesReadingGrade,
} from "components/GuidedSignUp/GuidedSignUpLayoutReadingGrade";
import GuidedSignUpLayoutReaderProfile, {
  getInitialValuesProfile,
} from "components/GuidedSignUp/GuidedSignUpLayoutReaderProfile";
import GuidedSignUpLayoutConfirmation from "components/GuidedSignUp/GuidedSignUpLayoutConfirmation";
import { guidedSignUpReplaceTemplateStrings } from "utils/guidedSignUpTemplating";
import { Answer, HoistFields, Step, UserAnswers } from "types/contentful";
import { ReadingLevelImageMap } from "utils/readingLevelImageUtils";
import { SubscriptionDetailsSchemaCamelCase } from "contexts/SubscriptionsProvider";
import { ReadingLevel } from "lib/api/types";
import { GuidedSignUpStorage } from "contexts/GuidedSignUpProvider";
import {
  getInitialValuesLayoutRadio,
  GuidedSignUpLayoutRadio,
} from "components/GuidedSignUp/GuidedSignUpLayoutRadio";
import GuidedSignUpLayoutFunnelRebase from "components/GuidedSignUp/GuidedSignUpLayoutFunnelRebase";
import { GuidedSignUpLayoutFunnelRebaseOptions } from "components/GuidedSignUp/GuidedSignUpFunnelRebaseAdapter";
import { KidsTypes } from "@literati/public-ui-shared-react";
import GuidedSignUpLayoutPlanDetails from "./GuidedSignUpLayoutPlanDetails";

export enum QuizLayout {
  CUSTOM = "custom",
  PILL_CLOUD = "pill_cloud",
  RADIO = "radio",
  MULTI_SELECT_ICONS = "multi_select_icons",
}

export enum QuizField {
  CHECKBOX = "checkbox",
  MULTI_SELECT_ICON = "multi_select_icon",
  PILL_CLOUD = "pill_cloud",
  RADIO = "radio",
  SELECT = "select",
  TEXT_INPUT = "text_input",
}

type ValidationMessage =
  | "reader-name"
  | "reader-birth-month"
  | "reader-birth-year"
  | "reading-level-adjustment-grade"
  | "reading-level-adjustment"
  | "reader-theme-preference";

// @TODO: enumerate these values in Contentful instead of using raw strings for `slug` field
const CustomValidationMessage = {
  "reader-name": "Please add your reader's name to continue.",
  "reader-birth-month": "Please select a month.",
  "reader-birth-year": "Please select a year.",
  "reading-level-adjustment-grade":
    "Please tell us what grade your reader is in.",
  "reading-level-adjustment": "Please provide a reading level estimate.",
  "reader-theme-preference": "Please choose at least one theme or topic.",
};

/**
 * Custom validator for theme preference.
 *
 * @note - We always submit all possible answer options with values set to `0` or `1`.
 *         This validator makes sure the initial state where all `value`s are `undefined`
 *         to prevent the user from proceeding without selecting at least 1 option.
 */
Yup.addMethod(Yup.mixed, "themePreference", function (errorMessage) {
  return this.test("test-theme-preference", errorMessage, function (value) {
    const { path, createError } = this;
    const hasUserValue = value?.filter(
      (option: Record<"value", any>) => option.value !== undefined
    ).length;
    return hasUserValue || createError({ path, message: errorMessage });
  });
});

/**
 * Determines if fieldType is in the multi answer set.
 */
export const isMultiAnswerField = (fieldType: QuizField) =>
  fieldType === QuizField.MULTI_SELECT_ICON ||
  fieldType === QuizField.PILL_CLOUD;

/**
 * Determines if fieldType has no default value in the CMS.
 */
export const hasNoCMSValueForFieldType = (fieldType: QuizField) =>
  fieldType === QuizField.CHECKBOX || fieldType === QuizField.TEXT_INPUT;

export interface GetDefaultInitialValuesArgs {
  step: Step;
  userAnswers: UserAnswers;
}

type InitialInputValues = any;

export interface GuidedSignUpQuizDefaultInitialValues {
  [questionSlug: string]: InitialInputValues;
}

/**
 * Creates default values for all form fields.
 */
export function getDefaultInitialValues({
  step,
  userAnswers,
}: GetDefaultInitialValuesArgs):
  | GuidedSignUpQuizDefaultInitialValues
  | undefined {
  return step?.questions?.reduce(
    (acc, question) => {
      const prevAnswer = question.slug ? userAnswers?.[question.slug] : null;
      const slug = question.slug;

      if (!slug) {
        return acc;
      }

      switch (question.fieldType) {
        case QuizField.TEXT_INPUT:
          return {
            ...acc,
            [slug]: prevAnswer ? prevAnswer?.[0]?.value : "",
          };
        case QuizField.CHECKBOX:
          return {
            ...acc,
            [slug]: prevAnswer ? prevAnswer?.[0]?.value : false,
          };
        default:
          return { ...acc, [slug]: prevAnswer || undefined };
      }
    },
    {} as {
      [questionSlug: string]: unknown;
    }
  );
}

/**
 * Gets custom initial values for theme preference step.
 */
export const getInitialValuesThemePreference = ({
  defaultInitialValues,
  isGuidedSignUpFunnel,
  step,
  storage,
  subscription,
}: {
  defaultInitialValues?: GuidedSignUpQuizDefaultInitialValues | null;
  isGuidedSignUpFunnel: boolean;
  step: Step;
  storage: GuidedSignUpStorage;
  subscription: SubscriptionDetailsSchemaCamelCase;
}) => {
  const themeKey = "reader-theme-preference";

  const previousAnswers = isGuidedSignUpFunnel
    ? storage?.userAnswers?.[themeKey]?.reduce(
        (acc: Record<string, string | undefined>, userAnswer: Answer) => ({
          ...acc,
          [camelCase(userAnswer.slug)]: userAnswer.value,
        }),
        {}
      )
    : subscription?.themePreference;

  const options = step?.questions?.[0]?.answers;
  const optionsWithAnswers = options?.map((option) => {
    const camelCaseSlug = camelCase(option?.slug);
    return { ...option, value: previousAnswers?.[camelCaseSlug] };
  });

  const customValues = {
    [themeKey]: optionsWithAnswers || defaultInitialValues?.[themeKey],
  };

  return { ...defaultInitialValues, ...customValues };
};

/**
 * Returns the initial values for the form. Leverages initial values functions
 * defined by respective Layout components.
 */
export function getInitialValuesGuidedSignUpQuizForm({
  isGuidedSignUpFunnel,
  slug,
  step,
  storage,
  subscription,
}: {
  isGuidedSignUpFunnel: boolean;
  slug: string;
  step: Step;
  storage: GuidedSignUpStorage;
  subscription: SubscriptionDetailsSchemaCamelCase;
}): GuidedSignUpQuizDefaultInitialValues | undefined | null {
  const defaultInitialValues = storage?.userAnswers
    ? getDefaultInitialValues({
        step,
        userAnswers: storage?.userAnswers,
      })
    : null;

  switch (slug) {
    case GuidedSignUpStepSlug.READER_PROFILE:
    case GuidedSignUpStepSlug.READER_PROFILE_FUNNEL:
      return getInitialValuesProfile({
        defaultInitialValues,
        isGuidedSignUpFunnel,
        storage,
        subscription,
      });
    case GuidedSignUpStepSlug.READER_GRADE:
      return getInitialValuesReadingGrade({
        defaultInitialValues,
        storage,
        subscription,
      });
    case GuidedSignUpStepSlug.READER_THEME_PREFERENCE:
      return getInitialValuesThemePreference({
        defaultInitialValues,
        isGuidedSignUpFunnel,
        step,
        storage,
        subscription,
      });
    case GuidedSignUpStepSlug.RELATIONSHIP:
    case GuidedSignUpStepSlug.BUDGET:
    case GuidedSignUpStepSlug.BUDGET_MONTHLY:
    case GuidedSignUpStepSlug.GENDER:
      return getInitialValuesLayoutRadio({
        questionKey: slug,
        defaultInitialValues,
        storage,
      });
    default:
      return defaultInitialValues;
  }
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 645px; // @TODO: make this configurable (some steps don't want to constrain)
`;

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2.75rem;
`;

const PillCloud = styled(GuidedSignUpPillCloud)`
  margin: 1.5rem 0 0;
`;
export interface GuidedSignUpQuizProps<Values> {
  className?: string;
  isGuidedSignUpFunnel: boolean;
  onQuizComplete: () => Promise<void>;
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => void | Promise<any>;
  postingQuizCompletion: boolean;
  step: Step;
  storage: GuidedSignUpStorage;
  subscription: SubscriptionDetailsSchemaCamelCase;
  readingLevel: ReadingLevel;
  readingLevelImages: ReadingLevelImageMap;
  readingLevelBadgesNoText: ReadingLevelImageMap;
}

/**
 * Controller for the quiz portion of guided sign up.
 */
function GuidedSignUpQuiz<Values extends FormikValues>({
  className,
  isGuidedSignUpFunnel,
  onQuizComplete,
  onSubmit,
  postingQuizCompletion,
  step,
  storage,
  subscription,
  readingLevel,
  readingLevelBadgesNoText,
}: GuidedSignUpQuizProps<Values>) {
  const { prompt, questions = [], subtext, layout, slug } = step;

  const initialValues = getInitialValuesGuidedSignUpQuizForm({
    isGuidedSignUpFunnel,
    slug,
    step,
    storage,
    subscription,
  });

  const childName =
    subscription?.childName ||
    storage?.userAnswers?.["reader-name"]?.[0]?.value ||
    "your reader";

  const formValidationSchema = Yup.object().shape(
    step?.questions?.reduce((acc, question) => {
      const slug = question.slug;
      if (!slug) {
        return acc;
      }

      return {
        ...acc,
        [slug]:
          question?.fieldType === QuizField.PILL_CLOUD
            ? (Yup.mixed() as any).themePreference(
                CustomValidationMessage[question?.slug as ValidationMessage] ??
                  "Required."
              )
            : Yup.string().required(
                CustomValidationMessage[question?.slug as ValidationMessage] ??
                  "Required."
              ),
      };
    }, {}) || {}
  );

  const singleQuestion = questions?.[0];

  const readingLevelName = readingLevel?.name?.toLowerCase?.();

  return (
    <Container className={className}>
      <Formik
        enableReinitialize
        initialValues={initialValues as Values & FormikValues}
        onSubmit={onSubmit}
        validationSchema={formValidationSchema}
      >
        <Form id={slug}>
          {layout === QuizLayout.PILL_CLOUD && (
            <>
              <GuidedSignUpHeading
                primaryHeading={guidedSignUpReplaceTemplateStrings(prompt, {
                  childName,
                })}
                subheading={subtext}
              />
              <PillCloud
                name={singleQuestion?.slug}
                options={singleQuestion?.answers || []}
              />
            </>
          )}
          {layout === QuizLayout.RADIO && (
            <GuidedSignUpLayoutRadio
              step={step}
              childName={childName}
              readingLevelName={readingLevelName}
            />
          )}
          {layout === QuizLayout.CUSTOM &&
            (slug === GuidedSignUpStepSlug.READER_PROFILE ||
              slug === GuidedSignUpStepSlug.READER_PROFILE_FUNNEL) && (
              <GuidedSignUpLayoutReaderProfile
                // @note - type migration shortcut, this flow implementation
                //         will be deprecated some time in the future
                step={step as any}
                childName={childName}
              />
            )}
          {layout === QuizLayout.CUSTOM &&
            slug === GuidedSignUpStepSlug.READER_GRADE && (
              <GuidedSignUpLayoutReadingGrade
                step={step}
                childName={childName}
              />
            )}
          {layout === QuizLayout.CUSTOM &&
            (slug === GuidedSignUpStepSlug.CONFIRMATION ||
              slug === GuidedSignUpStepSlug.CONFIRMATION_FUNNEL) && (
              <GuidedSignUpLayoutConfirmation
                onMount={onQuizComplete}
                postingQuizCompletion={postingQuizCompletion}
                readingLevel={readingLevel}
                step={step}
                storage={storage}
                childName={childName}
                readingLevelBadgesNoText={readingLevelBadgesNoText}
              />
            )}
          {layout === QuizLayout.CUSTOM &&
            slug === GuidedSignUpStepSlug.BUDGET_PLAN_DETAILS && (
              <GuidedSignUpLayoutPlanDetails
                readingLevel={readingLevel}
                step={
                  step as Step<
                    GuidedSignUpLayoutFunnelRebaseOptions,
                    HoistFields<KidsTypes.IManyReferencesFields>
                  >
                }
              />
            )}
          {layout === QuizLayout.CUSTOM &&
            slug === GuidedSignUpStepSlug.FUNNEL_REBASE_PLAN_SELECTION_3 && (
              <GuidedSignUpLayoutFunnelRebase
                readingLevel={readingLevel}
                step={
                  step as Step<
                    GuidedSignUpLayoutFunnelRebaseOptions,
                    HoistFields<KidsTypes.IManyReferencesFields>
                  >
                }
              />
            )}
        </Form>
      </Formik>
    </Container>
  );
}

export default GuidedSignUpQuiz;
