import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useField } from "formik";
import DLSErrorMessage from "components/DLS/DLSErrorMessage";
import AccessibleText from "@/atoms/AccessibleText/AccessibleText";

const TextFieldLabel = styled.label`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 4px;
  background: #fff;

  border: ${({ $activeBorderColor, $error, $focused, theme }) =>
    `1px solid ${
      $error
        ? theme.colors["error"]
        : $focused
        ? theme.colors[$activeBorderColor]
        : theme.colors["newsprint3"]
    }`};
`;

const TextInput = styled.input`
  color: #232323;
  flex: 1;
  font-size: 1rem;
  width: 100%;
  border-radius: 0.25rem;
  line-height: 1;
  background: transparent;

  &::placeholder {
    color: ${({ theme }) => `${theme.colors["warm-gray-3"]}`};
    font-size: 1rem;
  }

  padding: ${({ $showInlineLabel }) =>
    $showInlineLabel ? "1.8125rem 1rem 0.6875rem 1rem" : "1.25rem 1rem"};
`;

const InlineLabel = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ theme }) => `${theme.colors["warm-gray-3"]}`};
  font-size: 0.75rem;
  transition: padding 50ms linear;

  visibility: ${({ $showInlineLabel }) =>
    $showInlineLabel ? "visible" : "hidden"};

  padding: ${({ $showInlineLabel }) =>
    $showInlineLabel ? "0.5rem 1rem 0 1rem" : "1rem 1rem 0 1rem"};
`;

/**
 * Free text field.
 *
 * @note additional props provided to component are spread on the field
 *
 * @param {Object} props
 * @param {String} props.activeBorderColor - should be DLS approved color key
 * @param {String} props.label
 * @param {String} props.name
 * @param {String} props.placeholder
 * @param {Function} [props.onClick]
 */
function DLSTextField(props) {
  const {
    activeBorderColor,
    className = "",
    label,
    placeholder: inlineLabel,
    ...fieldProps
  } = props;
  const { name } = fieldProps;
  const [field, meta] = useField(name);
  const [focused, setFocused] = useState(false);

  const showInlineLabel = focused || meta.value;
  const placeholder = !focused ? inlineLabel : undefined;

  const { error, touched } = meta;
  const { onBlur } = field;
  const shouldDisplayError = touched && !!error;

  return (
    <>
      <TextFieldLabel
        className={className}
        $activeBorderColor={activeBorderColor}
        $error={shouldDisplayError}
        $focused={focused}
        htmlFor={name}
        $showInlineLabel={showInlineLabel}
      >
        <AccessibleText
          screenReaderText={<label htmlFor={name}>{label}</label>}
        />
        <InlineLabel $showInlineLabel={showInlineLabel}>
          {inlineLabel}
        </InlineLabel>
        <TextInput
          {...field}
          {...fieldProps}
          id={name}
          onBlur={(e) => {
            /** We must call Formik's `onBlur` manually to use Formik's `touched` meta property
             * since we are adding our own logic to this handler.
             * @see https://formik.org/docs/tutorial#visited-fields */
            onBlur?.(e);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
          $showInlineLabel={showInlineLabel}
        />
      </TextFieldLabel>
      {shouldDisplayError && <DLSErrorMessage message={error} />}
    </>
  );
}

DLSTextField.defaultProps = {
  activeBorderColor: "teal-shade-2",
};

DLSTextField.propTypes = {
  activeBorderColor: PropTypes.string,
  className: PropTypes.string,
  enableSubmit: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default DLSTextField;
