import PropTypes from "prop-types";
import styled from "styled-components";
import { Image } from "@literati/public-ui-shared-react";
import { BreakpointKey } from "theme/breakpoints";
import { colors } from "@literati/public-ui-shared-react";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 336px;
`;

const PrimaryHeading = styled.h1`
  font-family: hoefler;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
  color: ${colors.inkBlack};
  /* Override global h1 opacity */
  opacity: 1;
  margin: 0.75rem 0 0;

  ${({ theme }) => theme.media[BreakpointKey.LG]} {
    margin: 1rem 0 0;
  }
`;

const SubHeading = styled.p`
  font-size: 0.875rem;
  line-height: 1.4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.inkBlack};
  margin: 0.75rem 0 0;

  ${({ theme }) => theme.media[BreakpointKey.LG]} {
    margin: 0.875rem 0 0;
  }
`;

/**
 * Heading rendered at the top of each layout.
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Object} [props.imageConfig]
 * @param {ReactElement|String} [props.primaryHeading]
 * @param {ReactElement|String} [props.subheading]
 */
function GuidedSignUpHeading({
  className,
  imageConfig,
  primaryHeading,
  subheading,
}) {
  return (
    <Container className={className}>
      {imageConfig && <Image {...imageConfig} targetHeight={44} />}
      {primaryHeading && (
        <PrimaryHeading className="guided-sign-up-primary-heading">
          {primaryHeading}
        </PrimaryHeading>
      )}
      {subheading && <SubHeading>{subheading}</SubHeading>}
    </Container>
  );
}

GuidedSignUpHeading.propTypes = {
  className: PropTypes.string,
  imageConfig: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number,
  }),
  primaryHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default GuidedSignUpHeading;
