import styled from "styled-components";
import { LINKS } from "globalConfig";
import { assetUrl } from "utils";

const AppStoreImage = styled.img`
  width: 218px;
  height: 70px;
`;

/**
 * Opens a new tab on the Literati app in the Apple App Store.
 *
 * @param {Object} props
 * @param {Object} props.className
 * @param {() => void} [props.onClick]
 */
export const DLSAppleAppStoreButton = ({ className, onClick }) => (
  <a
    href={LINKS.kidsAppstore}
    rel="noreferrer"
    onClick={onClick}
    target="_blank"
  >
    <AppStoreImage
      alt="Download on the Apple App Store"
      className={className}
      src={assetUrl("/static/img/apple-app-store-badge.svg")}
    />
  </a>
);

const GooglePlayImage = styled.img`
  width: 225px;
  height: 67px;
`;

/**
 * Opens a new tab on the Literati app in the Google Play Store.
 *
 * @param {Object} props
 * @param {Object} props.className
 * @param {() => void} [props.onClick]
 */
export const DLSGooglePlayStoreButton = ({ className, onClick }) => (
  <a
    href={LINKS.kidsGoogleStore}
    target={"_blank"}
    rel="noreferrer"
    onClick={onClick}
  >
    <GooglePlayImage
      alt="Get it on Google Play"
      className={className}
      src={assetUrl("/static/img/google-play-badge.svg")}
    />
  </a>
);
