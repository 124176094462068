import styled, { css } from "styled-components";
import {
  KidsTypes,
  PageSectionClassName,
  colors,
  media,
} from "@literati/public-ui-shared-react";
import { ReadingLevel } from "lib/api/types";
import { HoistFields, Step } from "types/contentful";
import {
  getPropsAdapterGuidedSignUpFunnelRebase,
  GuidedSignUpLayoutFunnelRebaseOptions,
} from "components/GuidedSignUp/GuidedSignUpFunnelRebaseAdapter";
import GuidedSignUpBoxShippingDetails from "components/GuidedSignUp/GuidedSignUpBoxShippingDetails";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import PlanSelectionDisplay from "components/PlanSelection/PlanSelectionDisplay";

export interface GuidedSignUpLayoutPlanDetailsProps {
  readingLevel: ReadingLevel;
  step?: Step<
    GuidedSignUpLayoutFunnelRebaseOptions,
    HoistFields<KidsTypes.IManyReferencesFields>
  >;
}

const sharedWidthConstraint = css`
  width: 100%;
  max-width: 404px;
`;

const StyledGuidedSignUpHeading = styled(GuidedSignUpHeading)`
  margin: 0 1.5rem 1.5rem;
  color: ${colors.inkBlack};
`;

const StyledGuidedSignUpBoxShippingDetails = styled(
  GuidedSignUpBoxShippingDetails
)`
  .${PageSectionClassName.SUBTEXT} {
    ${sharedWidthConstraint}
    ${media.md} {
      margin: 0;
    }
  }
`;

export function GuidedSignUpLayoutPlanDetails({
  readingLevel,
  step,
}: GuidedSignUpLayoutPlanDetailsProps) {
  const primaryHeading = step?.prompt || "Your Book Club Plan";

  const { boxShippingDetailsProps, howItWorksProps } =
    getPropsAdapterGuidedSignUpFunnelRebase({ readingLevel, step }) || {};

  return (
    <>
      <StyledGuidedSignUpHeading primaryHeading={primaryHeading} />
      <PlanSelectionDisplay imageProps={howItWorksProps?.imageProps} />
      <StyledGuidedSignUpBoxShippingDetails {...boxShippingDetailsProps} />
    </>
  );
}

export default GuidedSignUpLayoutPlanDetails;
