import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { range } from "lodash";

dayjs.extend(advancedFormat);

export const getToday = () => {
  // YYYY-MM-DD
  const d = new Date();
  return [
    d.getFullYear(),
    ("0" + (d.getMonth() + 1)).slice(-2),
    ("0" + d.getDate()).slice(-2),
  ].join("-");
};

export const getTodayPlusOneYear = () => {
  const today = getToday();
  const [todayYear, ...rest] = today.split("-");
  return [+todayYear + 1, ...rest].join("-");
};

export const getDateString = (ds: dayjs.ConfigType) =>
  dayjs(ds).format("MMMM D, YYYY");
export const getSimpleDateString = (ds: dayjs.ConfigType) =>
  dayjs(ds).format("MMMM Do");

export const getAbbrevDateString = (ds: dayjs.ConfigType, year = true) =>
  dayjs(ds).format(`MMM D${year ? ", YYYY" : ""}`);

export const getAbbrevDeliveryDateString = (nextShippingWindow: string[]) => {
  const [rangeStart, rangeEnd] = nextShippingWindow || [];
  return rangeStart
    ? `${getAbbrevDateString(rangeStart, false)} - ${getAbbrevDateString(
        rangeEnd,
        false
      )}`
    : "";
};

export const getBirthYears = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return range(currentYear - 18, currentYear + 2).reverse();
};

/**
 * Determines if a date object is valid.
 *
 * @note isNaN() converts its parameter to a number.
 *       If the parameter is an object, that's done by calling the .valueOf()
 *       method of the object. In the case of Date instances that returns the
 *       timestamp, and it won't be NaN for any valid Date.
 *
 * @param {*} date
 * @returns Boolean
 */
export function isValidDate(date: unknown): date is Date {
  return date instanceof Date && !isNaN(date as any);
}

/**
 * Determines the number of months between two dates.
 *
 * @param {Object} date1
 * @param {Object} date2
 * @returns Number
 */
export function getDateDifferenceInMonths(date1: Date, date2: Date) {
  if (!isValidDate(date1) || !isValidDate(date2)) {
    return null;
  }

  let endDate, startDate;
  if (date1 > date2) {
    endDate = date1;
    startDate = date2;
  } else {
    endDate = date2;
    startDate = date1;
  }

  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

export function isToday(isoDatetime: string | null) {
  if (!isoDatetime) {
    return false;
  }
  const today = new Date();
  const date = new Date(isoDatetime);
  return (
    date.getUTCFullYear() == today.getUTCFullYear() &&
    date.getUTCDate() == today.getUTCDate() &&
    date.getUTCMonth() == today.getUTCMonth()
  );
}
