import PropTypes from "prop-types";
import styled from "styled-components";
import { useRouter } from "next/router";
import LiteratiLogoDark from "@/atoms/Icon/literati-dark";
import Icon from "@/atoms/Icon";
import Button from "@/atoms/Button";
import DLSBackButton from "components/DLS/DLSBackButton";
import { BreakpointKey } from "theme/breakpoints";

const Header = styled.header`
  height: 60px;
  width: 100vw;
  background: ${({ theme }) => theme.colors["white"]};
  box-shadow: 0px 0px 15px rgba(237, 235, 230, 0.5);

  ${({ theme }) => theme.media["md"]} {
    box-shadow: none;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;

const BackButton = styled(DLSBackButton)`
  position: absolute;
  left: 0;

  ${({ $suppressBackButtonBreakpoint, theme }) =>
    $suppressBackButtonBreakpoint &&
    theme.media[$suppressBackButtonBreakpoint]} {
    display: ${({ $suppressBackButtonBreakpoint }) =>
      $suppressBackButtonBreakpoint && "none"};
  }
`;

const LogoButton = styled(Button)`
  border-radius: 0;
`;

/**
 * Reusable global header.
 *
 * @note - Do not add position css to this component explicitly,
 *         instead compose those styles in specific use cases.
 *         @see `components/GuidedSignUp/GuidedSignUpStickyHeader`
 *
 * @param {Object} props
 * @param {String} props.className
 * @param {Boolean} props.disableBackButton
 * @param {MouseEventHandler} props.onBackClick
 * @param {String} props.suppressBackButtonBreakpoint
 */
function DLSHeader({
  className,
  disableBackButton,
  onBackClick,
  hideBackButton = false,
  suppressBackButtonBreakpoint,
}) {
  const router = useRouter();

  return (
    <Header className={className}>
      <div className="content-spacing">
        <Content>
          {!hideBackButton ? (
            <BackButton
              disabled={disableBackButton}
              onClick={() => (onBackClick ? onBackClick() : router.back())}
              suppressText
              $suppressBackButtonBreakpoint={suppressBackButtonBreakpoint}
            >
              <Icon name="chevron-right" size="1rem" />
            </BackButton>
          ) : null}
          <LogoButton level="unstyled" onClick={() => router.push("/")}>
            <LiteratiLogoDark />
          </LogoButton>
        </Content>
      </div>
    </Header>
  );
}

DLSHeader.defaultProps = {
  suppressBackButton: false,
  hideBackButton: false,
};

DLSHeader.propTypes = {
  className: PropTypes.string,
  disableBackButton: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  suppressBackButtonBreakpoint: PropTypes.oneOf(
    Object.values(BreakpointKey).map((key) => key)
  ),
};

export default DLSHeader;
