import PropTypes from "prop-types";
import styled from "styled-components";

const Label = styled.label`
  position: relative;
  display: inline-block;
  padding: 1rem;
  border-radius: 6.25rem;

  /* @TODO: used approved gray */
  border: 1px solid;
  border-color: ${({ $checked, theme }) =>
    !$checked ? theme.colors["newsprint3"] : "transparent"};

  /* @TODO: Katie will provide us with approved light perriwinkle */
  background: ${({ $checked, theme }) =>
    $checked ? "#B3C9FF4D" : theme.colors["white"]};

  transition: background 175ms ease-in-out;
  transition-property: background, opacity;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  appearance: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const PillText = styled.p`
  font-weight: 500;
  line-height: 1.375rem;
  text-align: center;
  color: ${({ $checked, theme }) =>
    $checked ? theme.colors["perriwinkle-dark"] : theme.colors["ink-black"]};
  user-select: none;
`;

/**
 * Individual pill for use in our pill cloud.
 *
 * @note - this is a custom checkbox input
 *
 * @component
 * @param {Object} props
 * @param {String} props.checked
 * @param {String} props.className
 * @param {Boolean} props.defaultChecked
 * @param {String} props.id - input id
 * @param {String} props.label - input label
 * @param {String} props.name - input name
 * @param {Function} props.onClick - passes selected state and label value to callback
 */
function GuidedSignUpPill({
  checked,
  className,
  defaultChecked,
  id,
  label,
  name,
  onClick,
}) {
  const handleClick = () => {
    onClick?.({ id, label, name });
  };

  return (
    <Label className={className} htmlFor={id} $checked={checked}>
      <Input
        defaultChecked={defaultChecked}
        data-testid={`guided-sign-up-pill-checkbox-${label}`}
        id={id || `guided-sign-up-pill-checkbox-${label}`}
        name={name || label}
        onClick={handleClick}
        $checked={checked}
        type="checkbox"
        role="checkbox"
      />
      <PillText $checked={checked}>{label}</PillText>
    </Label>
  );
}

GuidedSignUpPill.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default GuidedSignUpPill;
