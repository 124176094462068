import { css } from "styled-components";
import { footerOffsetValue } from "components/DLS/DLSStickyFooter";
import { headerOffsetValue } from "components/GuidedSignUp/GuidedSignUpStickyHeader";

export const layoutContent = css`
  display: flex;
  justify-content: center;
  padding: ${headerOffsetValue}px 1.5rem ${footerOffsetValue}px;
`;

export const layoutPage = css`
  position: relative;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors["white"]};
  background-image: ${({ $bgTheme, theme }) =>
    $bgTheme === "confirmation" &&
    `linear-gradient(to bottom, ${theme.colors["white"]} 50%, ${theme.colors["mint3"]} 0%)`};
`;

export const layoutQuiz = css`
  margin: 1.25rem 0 0;

  ${({ theme }) => theme.media.sm} {
    margin: 2rem 0 0;
  }
`;

export default {
  layoutContent,
  layoutPage,
  layoutQuiz,
};
