import PropTypes from "prop-types";
import DLSSelect from "components/DLS/DLSSelect";
import analytics from "analytics";

/**
 * Select component configured for guided sign up questions.
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Object} props.question
 */
function GuidedSignUpSelect({ className, question }) {
  return (
    <DLSSelect
      className={className}
      label={question?.label}
      name={question?.slug}
      onClick={() => analytics.trackAmplitudeClickEvent(question?.label)}
    >
      {question?.answers?.map((answer) => (
        <option key={answer?.slug} value={answer?.value || ""}>
          {answer?.label}
        </option>
      ))}
    </DLSSelect>
  );
}

GuidedSignUpSelect.propTypes = {
  className: PropTypes.string,
  question: PropTypes.shape({
    answer: PropTypes.shape({
      label: PropTypes.string,
      slug: PropTypes.string,
      value: PropTypes.string,
    }),
    label: PropTypes.string,
    slug: PropTypes.string,
  }),
};

export default GuidedSignUpSelect;
