import PropTypes from "prop-types";
import styled from "styled-components";
import DLSHeader from "components/DLS/DLSHeader";
import DLSProgressMeter from "components/DLS/DLSProgressMeter";
import { BreakpointKey } from "theme/breakpoints";

export const headerOffsetValue = 63;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
`;

const ProgressBars = styled.div`
  display: flex;
`;

const ProgressMeter = styled(DLSProgressMeter)`
  margin: ${({ $first }) => !$first && "0 0 0 2px"};
`;

/**
 * Guided Sign Up Sticky Header.
 *
 * @see https://app.shortcut.com/literati/story/48847/update-implement-sticky-header
 * @see https://www.notion.so/Guided-Sign-Up-e3c74390824547899b8b551ac850ef0c
 *
 * @note - Animations currently only work for linear progress forward and backwards.
 *         If we decide to allow multi-step progress, we will need to revisit the transitions.
 *
 * @param {Object} props
 * @param {Boolean} props.disableBackButton
 * @param {Boolean} props.loading
 * @param {Object} props.onBackClick
 * @param {Boolean} props.multipleSteps - uses numSteps
 * @param {Number} props.progress - percentage completion
 * @param {Number} props.stepIndex - indexed at 0
 * @param {Number} props.stepsLength - number of progress meter segments
 */
function GuidedSignUpStickyHeader({
  disableBackButton,
  loading,
  onBackClick,
  multipleSteps,
  progress,
  stepIndex,
  stepsLength,
}) {
  return (
    <Container>
      <DLSHeader
        disableBackButton={disableBackButton}
        onBackClick={onBackClick}
        suppressBackButtonBreakpoint={BreakpointKey.MD}
      />
      <div className="content-spacing">
        {multipleSteps ? (
          stepsLength && (
            <ProgressBars>
              {Array(stepsLength)
                .fill("step")
                .map((_, idx) => (
                  <ProgressMeter
                    $first={idx === 0}
                    key={`progress-meter-${idx}`}
                    progress={idx <= stepIndex ? 100 : 0}
                  />
                ))}
            </ProgressBars>
          )
        ) : (
          <ProgressMeter loading={loading} progress={progress} />
        )}
      </div>
    </Container>
  );
}

GuidedSignUpStickyHeader.propTypes = {
  disableBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  multipleSteps: PropTypes.bool,
  progress: PropTypes.number,
  stepIndex: PropTypes.number,
  stepsLength: PropTypes.number,
};

export default GuidedSignUpStickyHeader;
