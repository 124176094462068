import { HTMLAttributes, ReactNode, useState } from "react";
import styled from "styled-components";
import { useField } from "formik";
import DLSErrorMessage from "components/DLS/DLSErrorMessage";
import Icon from "components/atoms/Icon";
import colors from "theme/colors";

const FieldLabel = styled.label<{
  $activeBorderColor: keyof typeof colors;
  $error?: boolean;
  $focused?: boolean;
  $restingBorderColor: keyof typeof colors;
}>`
  position: relative;
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => theme.colors["white"]};

  color: ${({
    $activeBorderColor,
    $error,
    $focused,
    theme,
    $restingBorderColor,
  }) =>
    `${
      $error
        ? theme.colors["error"]
        : $focused
        ? theme.colors[$activeBorderColor]
        : theme.colors[$restingBorderColor]
    }`};
  border: 1px solid currentColor;
  border-radius: 0.25rem;
`;

const Select = styled.select`
  appearance: none;
  flex: 1;
  font-size: 1rem;
  outline: none;
  color: ${({ theme }) => theme.colors["ink-black"]};
  width: 100%;
  border-radius: 0.25rem;
  line-height: 1.25rem;
  background: transparent;

  padding: 1.8125rem 2rem 0.6875rem 1rem;
`;

const InlineLabel = styled.div<{ $error?: boolean; $focused?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-size: 0.75rem;
  transition: padding 50ms linear;
  padding: 0.5rem 1rem 0 1rem;

  color: ${({ $error, $focused, theme }) =>
    `${$error || $focused ? "currentColor" : theme.colors["warm-gray-3"]}`};
`;

export const Chevron = styled(Icon).attrs(() => ({
  name: "quiz-chevron-down",
}))`
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 1rem;
  transform: translateY(-35%);
`;

export interface DLSSelectProps extends HTMLAttributes<HTMLSelectElement> {
  /** collection of select <option>s */
  children?: ReactNode;
  /** should be DLS approved color key */
  activeBorderColor?: keyof typeof colors;
  className?: string;
  label: string;
  name: string;
  restingBorderColor?: keyof typeof colors;
}

/**
 * DLSSelect form component.
 * @note additional props provided to component are spread on the field
 */
function DLSSelect(props: DLSSelectProps) {
  const {
    children,
    activeBorderColor = "teal-shade-2",
    className = "",
    label,
    restingBorderColor = "newsprint3",
    ...fieldProps
  } = props;
  const { id: idProp, name } = fieldProps;
  const [field, meta] = useField(name);
  const [focused, setFocused] = useState(false);
  const id = idProp ?? name;
  const { error, touched } = meta;
  const { onBlur } = field;
  const shouldDisplayError = touched && !!error;

  return (
    <>
      <FieldLabel
        className={className}
        $activeBorderColor={activeBorderColor}
        $error={shouldDisplayError}
        $focused={focused}
        htmlFor={id}
        $restingBorderColor={restingBorderColor}
      >
        <InlineLabel $focused={focused}>{label}</InlineLabel>
        <Select
          {...field}
          {...fieldProps}
          as="select"
          id={id}
          onChange={(e) => {
            field?.onChange?.(e);
            fieldProps?.onChange?.(e);
          }}
          onBlur={(e) => {
            /** We must call Formik's `onBlur` manually to use Formik's `touched` meta property
             * since we are adding our own logic to this handler.
             * @see https://formik.org/docs/tutorial#visited-fields */
            onBlur?.(e);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
        >
          {children}
        </Select>
        <Chevron size="1rem" fill={colors["ink-black"]} />
      </FieldLabel>
      {shouldDisplayError && <DLSErrorMessage message={error} />}
    </>
  );
}
export default DLSSelect;
