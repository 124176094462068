import React, { ReactNode } from "react";
import styled from "styled-components";
import {
  Bullets,
  BulletsClassName,
  BulletsProps,
  ColorsKey,
  IconName,
  Image,
  ImageProps,
  Theme,
} from "@literati/public-ui-shared-react";

/** This is the className that will be pre-pended to all of GuidedSignUpHowItWorks's inner component classNames */
export const GUIDED_SIGN_UP_HOW_IT_WORKS_CLASS_BASE =
  "guided-sign-up-how-it-works";

/** These are all of the internal classNames that are used within the GuidedSignUpHowItWorks component */
export const GuidedSignUpHowItWorksClassName = {
  BASE: GUIDED_SIGN_UP_HOW_IT_WORKS_CLASS_BASE,
  CARD: `${GUIDED_SIGN_UP_HOW_IT_WORKS_CLASS_BASE}-card`,
  HEADING: `${GUIDED_SIGN_UP_HOW_IT_WORKS_CLASS_BASE}-heading`,
} as const;

export interface GuidedSignUpHowItWorksProps {
  bgColor?: ColorsKey;
  bulletsProps?: BulletsProps;
  className?: string;
  heading?: ReactNode;
  imageProps?: ImageProps;
}

const ImageOffsetContainer = styled.section<{ $imageHeight: number }>`
  position: relative;
  padding: ${({ $imageHeight }) => `${$imageHeight / 2}px 0 0`};
`;

const Card = styled.div<{ $bgColor: ColorsKey }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  background: ${({ $bgColor, theme }) => theme.colors[$bgColor]};
`;

const Heading = styled.h2<{ $imageHeight: number }>`
  font-size: 1.125rem;
  font-family: ${({ theme }) => theme.font.fontFamily.hoefler};
  font-weight: 600;
  margin: ${({ $imageHeight }) => `${$imageHeight / 2}px 0 0`};
`;

const StyledImage = styled(Image)<{ $imageHeight: number }>`
  position: absolute;
  margin: 0 auto;
  top: 0;
  height: ${({ $imageHeight }) => `${$imageHeight}px`};
  /** offset shadow so book box appear centered */
  transform: translateX(1rem);

  img {
    object-fit: contain;
  }
`;

const StyledBullets = styled(Bullets)`
  padding: 1rem 0.25rem;

  .${BulletsClassName.BULLET} {
    font-size: 0.875rem;
  }
`;

export const GuidedSignUpHowItWorksBulletsPropsDefaults: BulletsProps = {
  iconProps: {
    name: IconName.STAR,
    fill: "frenchBlueShade2",
  },
  items: [
    {
      textContent:
        "Receive a box of 5 books picked just for your child each month",
      key: "gsu-how-it-works-bullet-0",
    },
    {
      textContent: "Take a week to decide which ones they love",
      key: "gsu-how-it-works-bullet-1",
    },
    {
      textContent: "Buy the keepers and send back the rest",
      key: "gsu-how-it-works-bullet-2",
    },
  ],
};

const IMAGE_HEIGHT_DEFAULT = 104;
const IMAGE_WIDTH_DEFAULT = 217;

export const GuidedSignUpHowItWorksImagePropsDefaults: ImageProps = {
  src: "//images.ctfassets.net/vqtz4cq2w5ts/2bhUm1gyRed3X4RLGGKeGv/7795173ee789b1dc083ab64ac1f8c014/KIDS_Ranger_Reading_Level_Hero_SM_Cropped.png",
  width: IMAGE_WIDTH_DEFAULT,
  height: IMAGE_HEIGHT_DEFAULT,
};

export const GuidedSignUpHowItWorks = ({
  bgColor = "frenchBlueTint4",
  bulletsProps = GuidedSignUpHowItWorksBulletsPropsDefaults,
  className = "",
  heading = "How it works",
  imageProps = GuidedSignUpHowItWorksImagePropsDefaults,
}: GuidedSignUpHowItWorksProps) => {
  const imageHeight = imageProps.height || IMAGE_HEIGHT_DEFAULT;

  return (
    <Theme>
      <ImageOffsetContainer
        className={`${GuidedSignUpHowItWorksClassName.BASE} ${className}`}
        $imageHeight={imageHeight}
      >
        <Card
          $bgColor={bgColor}
          className={GuidedSignUpHowItWorksClassName.CARD}
        >
          <StyledImage {...imageProps} contain $imageHeight={imageHeight} />
          <Heading
            className={GuidedSignUpHowItWorksClassName.HEADING}
            $imageHeight={imageHeight}
          >
            {heading}
          </Heading>
          <StyledBullets {...bulletsProps} />
        </Card>
      </ImageOffsetContainer>
    </Theme>
  );
};

export default GuidedSignUpHowItWorks;
