import GuidedSignUpQuiz, {
  hasNoCMSValueForFieldType,
  isMultiAnswerField,
  QuizField
} from "components/GuidedSignUp/GuidedSignUpQuiz";
import { getYearAnswers } from "components/GuidedSignUp/GuidedSignUpLayoutReaderProfile";

/**
 * Normalizes an answer for a pill field type for the API.
 */
export function normalizeAnswerPillCloud({ question, userValue }) {
  if (!question || !userValue) {
    return [];
  }

  const normalizedAnswers = question?.answers?.map((answer) => {
    const foundAnswer = userValue.find((v) => v.slug === answer.slug);
    const derivedValue = !!foundAnswer
      ? foundAnswer.value
      : Number(answer.value);
    const value = isNaN(derivedValue) ? 0 : derivedValue;

    return { ...answer, value };
  });

  return normalizedAnswers;
}

/**
 * Builds an answer for a field type that accepts an answer set.
 */
export function normalizeAnswerMultiAnswerField({
  fieldType,
  normalizeFn = normalizeAnswerPillCloud,
  question,
  userValue,
}) {
  return fieldType === QuizField.PILL_CLOUD
    ? normalizeFn({ question, userValue })
    : userValue;
}

/**
 * Builds an answer for a field that has no default CMS value.
 *
 * @note - these fields have one answer with no value, hence the
 *         destructuring of question.answers as an array value
 */
export function normalizeAnswerNoCMSValue({ question, userValue }) {
  if (!question || userValue === undefined) {
    return {};
  }

  const [answer] = question?.answers;
  const normalizedAnswer = { ...answer, value: userValue };
  return normalizedAnswer;
}

/**
 * Gets valid answer set.
 *
 * @note - reader birth year is a special case where we derive
 *         the dates in the application versus the CMS.
 */
export function getAnswerDomain({ answerFn = getYearAnswers, question }) {
  return question.slug === "reader-birth-year" ? answerFn() : question?.answers;
}

/**
 * Builds an answer for the non-special case answers.
 *
 * @note - coerces numbers to string before submitting to the API
 */
export function normalizeAnswerGeneric({ answerDomain, userValue }) {
  const answer = answerDomain?.find((answer) => {
    const value =
      typeof userValue === "number" ? userValue?.toString() : userValue;
    return answer.value === value;
  });

  return answer || null;
}

/**
 * Merges form field value and answer metadata back together before submission.
 *
 * @note - omits answers from payload if required metadata is not provided
 * @see https://literati.dev/api/docs#/onboarding/subscriptions_api_onboarding_submit_guided_signup_answer
 */
export function normalizeAnswers({ answers, step }) {
  if (!answers || !step) {
    return null;
  }

  return Object.keys(answers).reduce((acc, answerSlug) => {
    const question = step?.questions?.find(
      (question) => question.slug === answerSlug
    );

    const userValue = answers?.[answerSlug];
    const fieldType = question?.fieldType;

    if (userValue === undefined || !fieldType || !question?.slug) {
      return acc;
    }

    if (isMultiAnswerField(fieldType)) {
      const answer = normalizeAnswerMultiAnswerField({
        fieldType,
        question,
        userValue,
      });

      return { ...acc, [question.slug]: [...answer] };
    } else {
      const answerDomain = getAnswerDomain({ question });

      const answer = hasNoCMSValueForFieldType(fieldType)
        ? normalizeAnswerNoCMSValue({ question, userValue })
        : normalizeAnswerGeneric({ answerDomain, userValue });

      return { ...acc, [question.slug]: [answer] };
    }
  }, {});
}

export default normalizeAnswers;
