import { ReactNode } from "react";
import styled from "styled-components";

/** This is the className that will be pre-pended to all of MagazineReviewDetails's inner component classNames */
export const DLS_ERROR_MESSAGE_CLASS_BASE = "dls-error-message";

/** These are all of the internal classNames that are used within the MagazineReviewDetails component */
export const DLSErrorMessageClassName = {
  BASE: DLS_ERROR_MESSAGE_CLASS_BASE,
  TEXT: `${DLS_ERROR_MESSAGE_CLASS_BASE}-text`,
} as const;

interface DLSErrorMessageProps {
  className?: string;
  type?: string;
  message: ReactNode;
}

export const ErrorMessageType = {
  FIELD: "field",
};

const Container = styled.section<{ $type?: string }>`
  padding: ${({ $type }) =>
    $type === ErrorMessageType.FIELD && "0.5rem 0 0 1rem"};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors["error"] || theme.colors.errorRed};
  font-size: 0.875rem;
`;

function DLSErrorMessage({
  className,
  message,
  type = ErrorMessageType.FIELD,
}: DLSErrorMessageProps) {
  const cls = DLSErrorMessageClassName;

  return (
    <Container className={`${cls.BASE} ${className}`} $type={type}>
      <Text className={cls.TEXT}>{message}</Text>
    </Container>
  );
}

export default DLSErrorMessage;
