/**
 * Constructs query string for redirect based on current url state.
 *
 * @param {Object} args
 * @param {Boolean} args.preserveTokenQuery
 * @param {Object} args.query
 */
export function buildGuidedSignUpQueryString(args) {
  const { preserveTokenQuery = true, query } = args || {};

  if (!query) {
    return "";
  }
  const token = preserveTokenQuery ? query?.token : null;
  return `${token ? `?token=${token}` : ""}`;
}

/**
 * Dynamically builds Guided Sign Up route based on relative path.
 *
 * @param {Object} args
 * @param {Object} args.contentfulQuizModel
 * @param {Object} args.preserveTokenQuery
 * @param {Object} args.router
 * @param {String} args.slug
 */
function getNavigationRoute({
  contentfulQuizModel,
  preserveTokenQuery = true,
  router,
  slug,
}) {
  if (!contentfulQuizModel || !router || !slug) {
    return "";
  }

  const relativeRouteSlug = contentfulQuizModel?.relativeRouteSlug;
  const [base] = router?.asPath?.split(relativeRouteSlug);

  const queryParamString = buildGuidedSignUpQueryString({
    query: router?.query,
    preserveTokenQuery,
  });

  const route = `${base}${relativeRouteSlug}/${slug}/${queryParamString}`;
  return route;
}

export default getNavigationRoute;
