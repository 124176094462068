import PropTypes from "prop-types";
import styled from "styled-components";
import GuidedSignUpDisplayPill from "components/GuidedSignUp/GuidedSignUpDisplayPill";
import Icon from "@/atoms/Icon";
import { Image } from "@literati/public-ui-shared-react";
import { formatContentfulImageForNextImage } from "utils/contentful/helpers";
import { guidedSignUpReplaceTemplateStrings } from "utils/guidedSignUpTemplating";

const IMAGE_TARGET_HEIGHT_PIXELS = 150;

const IMAGE_SPACING_HEIGHT = IMAGE_TARGET_HEIGHT_PIXELS / 2 + 16;

const CARD_PADDING = "1rem";

const Card = styled.section`
  width: calc(100% - 2.5rem);
  max-width: 600px;
  padding: ${CARD_PADDING};
  border: 1px solid ${({ theme }) => theme.colors["newsprint1"]};
  border-radius: 0.5rem;
  margin-top: ${IMAGE_SPACING_HEIGHT}px;
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${IMAGE_SPACING_HEIGHT}px;

  // center image along the top of edge the card
  transform: translateY(calc(-50% - ${CARD_PADDING}));

  & > span {
    // allow inner image drop-shadow to extend outside the default Next.js span container
    overflow: visible !important;
  }

  img {
    // using a filter allows the shadow to conform to the image, rather than being a square
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  }
`;

const ReadingLevelEyebrow = styled.h3`
  font-family: avenir;
  font-weight: 900;
  font-size: 0.6rem;
  text-align: center;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors["warm-gray-3"]};
`;

const ReadingLevelName = styled.p`
  font-weight: bold;
  font-family: hoefler;
  font-size: 1.8rem;
  text-align: center;
  color: ${({ theme }) => theme.colors["ink-black"]};
`;

const StyledHorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors["newsprint1"]};
  margin: 1rem 0;
`;

const PillFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
`;

const ReadingLevelSubHeading = styled.h4`
  font-family: avenir;
  font-weight: 800;
  font-size: 0.875rem;
`;

const ReadingLevelListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.875rem 0;
`;

const BodyText = styled.p`
  font-family: avenir;
  font-size: 0.875rem;
`;

const ReadingLevelListItemText = styled(BodyText)`
  margin-left: 1rem;
`;

/**
 * Displays details about a child's current reading level.
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className]
 * @param {object} props.cardCopy
 * @param {string} props.cardCopy.eyebrow
 * @param {string} props.cardCopy.skillsHeading
 * @param {string} props.cardCopy.bookTypesHeading
 * @param {Object} props.readingLevel
 * @param {Object} props.readingLevelImage
 */
function GuidedSignUpReadingLevelCard({
  className,
  readingLevel,
  cardCopy,
  readingLevelImage,
}) {
  if (!readingLevel || !cardCopy) {
    return null;
  }

  const readingLevelName = readingLevel?.name;
  const skillsHeading = guidedSignUpReplaceTemplateStrings(
    cardCopy?.skillsHeading,
    {
      readingLevelName,
    }
  );

  return (
    <Card className={className}>
      {readingLevelImage && (
        <ImageContainer>
          <Image
            {...formatContentfulImageForNextImage(readingLevelImage)}
            targetHeight={IMAGE_TARGET_HEIGHT_PIXELS}
          />
        </ImageContainer>
      )}
      <ReadingLevelEyebrow>{cardCopy?.eyebrow}</ReadingLevelEyebrow>
      <ReadingLevelName>{readingLevel?.name}</ReadingLevelName>
      <PillFlexContainer>
        {readingLevel?.["relevant-age"]?.split(",")?.map((age) => (
          <GuidedSignUpDisplayPill key={`reading-age-${age}`}>
            {age?.trim()}
          </GuidedSignUpDisplayPill>
        ))}
      </PillFlexContainer>
      <BodyText>{readingLevel?.description}</BodyText>
      <StyledHorizontalRule />
      <ReadingLevelSubHeading>{skillsHeading}</ReadingLevelSubHeading>
      <ul>
        {readingLevel?.skills?.map((skill) => (
          <ReadingLevelListItem key={`skill-${skill}`}>
            <Icon name="quiz-star" size="14px" />
            <ReadingLevelListItemText>{skill}</ReadingLevelListItemText>
          </ReadingLevelListItem>
        ))}
      </ul>
      <StyledHorizontalRule />
      <ReadingLevelSubHeading>
        {cardCopy?.bookTypesHeading}
      </ReadingLevelSubHeading>
      <ul>
        {readingLevel?.["book-types"]?.map((bookTypes) => (
          <ReadingLevelListItem key={`book-types-${bookTypes}`}>
            <Icon name="quiz-star" size="14px" />
            <ReadingLevelListItemText>{bookTypes}</ReadingLevelListItemText>
          </ReadingLevelListItem>
        ))}
      </ul>
    </Card>
  );
}

GuidedSignUpReadingLevelCard.propTypes = {
  className: PropTypes.string,
  readingLevel: PropTypes.shape({
    ["book-types"]: PropTypes.array,
    name: PropTypes.string,
    ["relevant-age"]: PropTypes.string,
    skills: PropTypes.array,
  }),
  cardCopy: PropTypes.object,
};

export default GuidedSignUpReadingLevelCard;
