import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors["gray1"]};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`;

const flash = keyframes`
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 100%;
  }
`;

const Meter = styled.div`
  background: ${({ $color, theme }) => theme.colors[$color]};
  height: 100%;
  width: ${({ $progress }) => $progress}%;

  transition: width 0.5s ease;
  animation-name: ${({ $loading }) => $loading && flash};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

/**
 * Simple animated progress meter.
 *
 * @note - initial use case `DLSStickyHeader`
 *
 * @component
 * @param {Object} props
 * @param {String} props.className
 * @param {String} props.color - must be an approved DLS color
 * @param {String} props.height - must be formatted as css, e.g. 3px or 100%
 * @param {Boolean} props.loading
 * @param {Number} props.progress - represents percentage value
 * @param {String} props.width - must be formatted as css, e.g. 3px or 100%
 */
function DLSProgressMeter({
  className,
  color,
  height,
  loading,
  progress,
  width,
}) {
  const [prog, setProg] = useState(0);

  // allows for initial transition
  useEffect(() => {
    setProg(progress);
  }, [progress]);

  return (
    <Container className={className} $height={height} $width={width}>
      <Meter $color={color} $loading={loading} $progress={prog} />
    </Container>
  );
}

DLSProgressMeter.defaultProps = {
  color: "perriwinkle",
  height: "3px",
  progress: 0,
  width: "100%",
};

DLSProgressMeter.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  height: PropTypes.string,
  progress: PropTypes.number,
  width: PropTypes.string,
};

export default DLSProgressMeter;
