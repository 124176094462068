import { postCompleteGuidedQuiz } from "lib/api/onboarding";

/**
 * Encapsulates logic to signal completion of guided sign up
 * and derive a reading level for the confirmation step.
 *
 * @param {Object} args
 * @param {String} args.quizSlug
 * @param {Number} args.quizVersion
 * @param {Function} args.responseParser
 * @param {Function} args.setReadingLevel
 * @param {String} args.subscriptionId
 */
export async function updateReadingLevel({
  asyncPostFn = postCompleteGuidedQuiz,
  quizSlug,
  quizVersion,
  responseParser,
  setReadingLevel,
  subscriptionId,
}) {
  if (
    !quizSlug ||
    (typeof quizVersion !== "number" && !quizVersion) ||
    !responseParser ||
    !setReadingLevel
  ) {
    console.error(
      "GuidedSignUp:",
      "Couldn't post completed guided sign up quiz, because some arguments are not defined",
      { quizSlug, quizVersion }
    );
    return false;
  }

  const res = await asyncPostFn({
    quizSlug,
    quizVersion,
    subscriptionId,
  });

  responseParser({
    onSuccess: () => {
      const readingLevelName = res?.subscription?.readingLevelName || "";
      if (!readingLevelName) {
        console.error(
          "GuidedSignUp",
          "Could not determine user's readingLevelName from postCompleteGuidedQuiz",
          { res, readingLevelName }
        );
        return false;
      }

      setReadingLevel(readingLevelName);
    },
    onError: () => {
      console.error("GuidedSignUp", "API failure: postCompleteGuidedQuiz", {
        res,
      });
      return false;
    },
    res,
    toastOnSuccess: false,
  });
}

export default updateReadingLevel;
