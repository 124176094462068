import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useField } from "formik";
import analytics from "analytics";
import DLSErrorMessage from "components/DLS/DLSErrorMessage";
import GuidedSignUpPill from "components/GuidedSignUp/GuidedSignUpPill";

const FieldSet = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.8125rem 0 0; // first row offset minus outline pixel
`;

const Legend = styled.legend`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Pill = styled(GuidedSignUpPill)`
  margin: 0.875rem 0.4375rem 0;
`;

/**
 * Presents "pill cloud" that is represented as a fieldset of checkboxes.
 *
 * @component
 * @param {Object} props
 * @param {String} props.className
 * @param {String} props.groupLabel - hidden group label for a11y
 * @param {Array} props.initialValue
 * @param {Function} props.onClick - passes selected options to callback as the form value
 * @param {Array} props.options - step.answers
 */
export function GuidedSignUpPillCloud({
  className,
  groupLabel,
  initialValue,
  onClick,
  options,
}) {
  const [opts, setOpts] = useState();

  // @TODO: revisit architecture of this field
  useEffect(() => {
    setOpts(
      options.map((opt) => {
        // @NOTE - because we submit all pills for themes, finding the option is extra work,
        //         but I am going to leave it, as we may have pill clouds that don't require
        //         submitting all options. We can revisit on the second pill cloud.
        const foundOption = initialValue?.find((v) => v.slug === opt.slug);
        return {
          ...opt,
          checked: !!foundOption?.value,
          value: foundOption?.value,
        };
      })
    );
  }, [initialValue]);

  const handleClick = ({ label }) => {
    const newOpts = opts.map((opt) => {
      const newCheckedValue = !opt.checked;
      return opt.label === label
        ? { ...opt, checked: newCheckedValue, value: newCheckedValue ? 1 : 0 }
        : opt;
    });
    setOpts(newOpts);

    const selectedOptions = newOpts.reduce((acc, opt) => {
      return opt.checked ? [...acc, opt] : acc;
    }, []);
    onClick?.(selectedOptions?.length ? selectedOptions : null);

    analytics.trackAmplitudeClickEvent(
      label,
      newOpts?.find((opt) => opt.label === label)?.value
    );
  };

  return (
    <FieldSet className={className}>
      <Legend>{groupLabel}</Legend>
      {opts?.map((option) => {
        const { checked, slug, label } = option;
        const id = `guided-sign-up-pill-checkbox-${slug}`;
        return (
          <Pill
            checked={checked}
            defaultChecked={checked}
            id={id}
            key={id}
            name={label}
            onClick={handleClick}
            label={label}
          />
        );
      })}
    </FieldSet>
  );
}

GuidedSignUpPillCloud.defaultProps = {
  groupLabel: "set of checkboxes",
  initialValue: [],
  options: [],
};

GuidedSignUpPillCloud.propTypes = {
  className: PropTypes.string,
  groupLabel: PropTypes.string,
  initialValue: PropTypes.arrayOf(
    PropTypes.shape({
      preferenceId: PropTypes.string,
      slug: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      preferenceId: PropTypes.string,
      slug: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

/**
 * Formik connected pill cloud.
 *
 * @note - this formik field acts as a fieldset of checkboxes
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className] - this is set on GuidedSignUpPillCloud
 * @param {String} [props.groupLabel] - hidden group label for a11y
 * @param {String} props.name - field name identifier
 * @param {Array} props.options
 * @param {Boolean} [props.showError]
 */
function GuidedSignUpPillCloudField(props) {
  const {
    className,
    groupLabel,
    options,
    showError = true,
    ...fieldProps
  } = props;

  const [field, meta, helpers] = useField(fieldProps);
  // @TODO: initialValue getting undefined for a render pass
  //        pillcloud/pill updated to mitigate this in lieu of real fix
  const { initialValue } = meta;
  const { setValue } = helpers;

  return (
    <>
      <GuidedSignUpPillCloud
        {...field}
        className={className}
        groupLabel={groupLabel}
        initialValue={initialValue}
        onClick={(selectedOptions) => setValue(selectedOptions)}
        options={options}
      />
      {meta?.error && showError && <DLSErrorMessage message={meta?.error} />}
    </>
  );
}

GuidedSignUpPillCloudField.defaultProps = {
  name: "pill_cloud", // default matches contentful step.displayType
};

GuidedSignUpPillCloudField.propTypes = {
  className: PropTypes.string,
  groupLabel: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default GuidedSignUpPillCloudField;
