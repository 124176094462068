import { isAPIError } from "lib/api/base";
import { normalizeAnswers, postAnswers } from "utils/GuidedSignUp";
import { GuidedSignUpStepSlug } from "utils/hooks/useGuidedSignUp";
import { FormikSubmitStep } from "components/Step/stepTypes";
import { FormikHelpers } from "formik";
import { Subscription } from "lib/api/subscription";

export type GuidedSignUpStepSlugValue =
  typeof GuidedSignUpStepSlug[keyof typeof GuidedSignUpStepSlug];

export function getContentfulQuizStep(
  contentfulQuizModel: any,
  slug: GuidedSignUpStepSlugValue
) {
  return contentfulQuizModel?.quizSteps?.find(
    (step: { slug: GuidedSignUpStepSlugValue }) => step.slug === slug
  );
}

export async function gsuSubmitAnswersDefault<Values>({
  contentfulQuizModel,
  formikHelpers,
  onError,
  onSubmit,
  step,
  subscription,
  values,
}: {
  contentfulQuizModel: any;
  formikHelpers?: FormikHelpers<Values>;
  onError?: (res: any) => void;
  onSubmit: FormikSubmitStep<Values>;
  step: any;
  subscription?: Subscription;
  values: Values;
}) {
  const normalizedAnswers = normalizeAnswers({ answers: values, step });

  const res = await postAnswers({
    normalizedAnswers,
    quizSlug: contentfulQuizModel.slug,
    quizVersion: contentfulQuizModel.version,
    step,
    subscription,
  });

  if (isAPIError(res)) {
    onError?.(res);
  } else {
    onSubmit?.(values, formikHelpers);
  }
}
