import React, { ReactNode } from "react";
import styled from "styled-components";
import { ColorsKey, Theme } from "@literati/public-ui-shared-react";
import GuidedSignUpCollapsible from "components/GuidedSignUp/GuidedSignUpCollapsible";

/** This is the className that will be pre-pended to all of GuidedSignUpFaq's inner component classNames */
export const GUIDED_SIGN_UP_FAQ_CLASS_BASE = "guided-sign-up-faq";

/** These are all of the internal classNames that are used within the GuidedSignUpFaq component */
export const GuidedSignUpFaqClassName = {
  BASE: GUIDED_SIGN_UP_FAQ_CLASS_BASE,
  COLLAPSIBLE_CONTAINER: `${GUIDED_SIGN_UP_FAQ_CLASS_BASE}-collapsible-container`,
  COLLAPSIBLE: `${GUIDED_SIGN_UP_FAQ_CLASS_BASE}-collapsible`,
  HEADING: `${GUIDED_SIGN_UP_FAQ_CLASS_BASE}-heading`,
} as const;

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 900;
`;

const Container = styled.section`
  ${({ theme }) => theme.media.sm} {
    border-radius: 6px;
  }
`;

const CollapsibleContainer = styled.div<{ $isLastItem: boolean }>`
  padding: 1.5rem 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.newsprint2}`};

  ${({ theme }) => theme.media.sm} {
    border-bottom: ${({ $isLastItem }) => $isLastItem && "none"};
  }
`;

export type GuidedSignUpFaqQuestionAnswer = {
  answer: ReactNode;
  question: ReactNode;
};

export interface GuidedSignUpFaqProps {
  bgColor?: ColorsKey;
  className?: string;
  questionAnswers?: GuidedSignUpFaqQuestionAnswer[];
  heading?: ReactNode;
}

export const GuidedSignUpFaqQuestionAnswersDefaults = [
  {
    question: "How do returns work?",
    answer:
      "A prepaid return label comes in every box. Just put any books you don’t want back in the box, stick the return label on, and schedule a pickup with USPS. They’ll take it from there. Shipping and returns are 100% free of charge.",
  },
  {
    question: "Can I see the books before they’re sent?",
    answer:
      "Afer we choose books for your reader’s box, you can preview the picks that are coming your way in the Literati Kids app. And after you get the box, rate the books in the app to help us curate the next set of picks for your reader.",
  },
  {
    question: "How do you pick books for my reader?",
    answer:
      "We start with our own catalog of high quality children’s literature. Then we consider your reader’s age and reading level to choose books that aren’t too hard or too easy. Finally, we factor in any topics they like and add new topics for them to explore each month. After you get your first box, we also consider books you’ve kept so far - that’s a good sign of what your reader loves!",
  },
];

export const GuidedSignUpFaq = ({
  bgColor = "papierBlanc",
  className = "",
  questionAnswers = GuidedSignUpFaqQuestionAnswersDefaults,
  heading = "FAQ",
}: GuidedSignUpFaqProps) => {
  return (
    <Theme>
      <Container
        className={`${GuidedSignUpFaqClassName.BASE} ${className}`}
        css={`
          background: ${bgColor};
        `}
      >
        <Heading className={`${GuidedSignUpFaqClassName.HEADING}`}>
          {heading}
        </Heading>
        {questionAnswers.map((qa, idx) => (
          <CollapsibleContainer
            className={`${GuidedSignUpFaqClassName.COLLAPSIBLE_CONTAINER}`}
            $isLastItem={questionAnswers.length - 1 === idx}
            key={`faq-collapsible-${idx}`}
          >
            <GuidedSignUpCollapsible
              className={`${GuidedSignUpFaqClassName.COLLAPSIBLE}`}
              heading={qa.question}
            >
              {qa.answer}
            </GuidedSignUpCollapsible>
          </CollapsibleContainer>
        ))}
      </Container>
    </Theme>
  );
};

export default GuidedSignUpFaq;
