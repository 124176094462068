import React, { ReactNode } from "react";
import styled from "styled-components";
import { Ribbon, RibbonProps, Theme } from "@literati/public-ui-shared-react";

/** This is the className that will be pre-pended to all of GuidedSignUpPriceInfo's inner component classNames */
export const GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE = "guided-sign-up-price-info";

/** These are all of the internal classNames that are used within the GuidedSignUpPriceInfo component */
export const GuidedSignUpPriceInfoClassName = {
  BASE: GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE,
  CONTAINER: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-container`,
  CARD: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-card`,
  PRICE: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-price`,
  PRICE_LARGE_FONT: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-price-large-font`,
  PRICE_MEDIUM_FONT: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-price-medium-font`,
  PRICE_SMALL_FONT: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-price-small-font`,
  SUBTEXT: `${GUIDED_SIGN_UP_PRICE_INFO_CLASS_BASE}-subtext`,
} as const;

export interface GuidedSignUpPriceInfoProps {
  className?: string;
  priceWholeDollarAmount?: ReactNode;
  priceCents?: ReactNode;
  priceCadence?: ReactNode;
  ribbonProps?: RibbonProps;
  subtext?: ReactNode;
}

const ImageOffsetContainer = styled.section`
  position: relative;
  padding: 14px 0 0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.newsprint3}`};
  border-radius: 12px;
`;

const StyledRibbon = styled(Ribbon)`
  position: absolute;
  margin: 0 auto;
  top: 0;
`;

const PriceBigFont = styled.span`
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
`;

const PriceMediumFont = styled.span`
  font-size: 1.5rem;
  font-weight: 800;
`;

const PriceSmallFont = styled.span`
  font-size: 0.8125rem;
  font-weight: 900;
  margin: 0 0 0 0.375rem;
`;

const Subtext = styled.p`
  font-size: 0.875rem;
  text-align: center;
`;

export const GuidedSignUpPriceInfoRibbonDefaults: RibbonProps = {
  bgColor: "frenchBlueShade2",
  /** @TODO: add updated default value */
  label: "MONTHLY BOOK BOX",
  labelColor: "white",
  preset: "SM",
  variant: "BOTH",
};

export const GuidedSignUpPriceInfo = ({
  className = "",
  priceWholeDollarAmount = 9,
  priceCents = 95,
  priceCadence = "month",
  ribbonProps = GuidedSignUpPriceInfoRibbonDefaults,
  subtext = "plus the cost of books you buy",
}: GuidedSignUpPriceInfoProps) => {
  return (
    <Theme>
      <ImageOffsetContainer
        className={`${GuidedSignUpPriceInfoClassName.BASE} ${className}`}
      >
        <Card className={GuidedSignUpPriceInfoClassName.CARD}>
          <StyledRibbon {...ribbonProps} />
          <p className={GuidedSignUpPriceInfoClassName.PRICE}>
            <PriceBigFont
              className={GuidedSignUpPriceInfoClassName.PRICE_LARGE_FONT}
            >
              ${priceWholeDollarAmount}
            </PriceBigFont>
            <PriceMediumFont
              className={GuidedSignUpPriceInfoClassName.PRICE_MEDIUM_FONT}
            >
              .{priceCents}
            </PriceMediumFont>
            <PriceSmallFont
              className={GuidedSignUpPriceInfoClassName.PRICE_SMALL_FONT}
            >
              / {priceCadence}
            </PriceSmallFont>
          </p>
          <Subtext className={GuidedSignUpPriceInfoClassName.SUBTEXT}>
            {subtext}
          </Subtext>
        </Card>
      </ImageOffsetContainer>
    </Theme>
  );
};

export default GuidedSignUpPriceInfo;
