import { ImageProps, KidsTypes } from "@literati/public-ui-shared-react";
import { Asset } from "contentful";
import { ReadingSkillJsonItem, HoistFields } from "types/contentful";

/** Extracts a reading level name from the raw Image entry */
export type GetReadingLevelNameFromEntryFn = (
  imageEntry: KidsTypes.IImage
) => string;

export interface ComposeReadingLevelImagesArgs {
  /** This type can be any assortment of Image Entries grouped into a `References (Many)` Entry */
  cmsReadingLevelImages: KidsTypes.IManyReferences;
  cmsReadingLevels: KidsTypes.IJson;
  getReadingLevelNameFromImageEntry?: GetReadingLevelNameFromEntryFn;
  userReadingLevelName: string;
}

export type ComposedReadingLevelImages =
  | (ReadingSkillJsonItem & {
      name?: string;
      imageEntry?: KidsTypes.IImage;
    })
  | {}; // eslint-disable-line @typescript-eslint/ban-types

export function composeReadingLevelImages({
  cmsReadingLevelImages,
  cmsReadingLevels,
  getReadingLevelNameFromImageEntry = getReadingLevelNameFromEntryName,
  userReadingLevelName,
}: ComposeReadingLevelImagesArgs): ComposedReadingLevelImages {
  let composedUserReadingLevel = null;

  try {
    const userReadingLevelImage = (
      cmsReadingLevelImages?.fields?.references as KidsTypes.IImage[]
    )?.find((imageEntry) => {
      const readingLevelName = getReadingLevelNameFromImageEntry(imageEntry);
      return readingLevelName === userReadingLevelName;
    });

    const userReadingLevelData: ReadingSkillJsonItem | undefined =
      cmsReadingLevels?.fields?.json?.find(
        (readingLevel: ReadingSkillJsonItem) => {
          return readingLevel?.name?.toLowerCase?.() === userReadingLevelName;
        }
      );

    composedUserReadingLevel = {
      ...userReadingLevelData,
      name: userReadingLevelName,
      imageEntry: userReadingLevelImage || null,
    };
  } catch (e) {
    console.error("Error composing reading level images: ", e);
  }

  return composedUserReadingLevel ?? {};
}

export type ReadingLevelImageMap = Record<string, Asset | undefined>;

export const mapImageEntryReferencesByName = (
  manyReferencesEntry: KidsTypes.IManyReferences,
  getNameFromImageEntry = (entry: KidsTypes.IImage) => entry?.fields?.name
): ReadingLevelImageMap =>
  (manyReferencesEntry?.fields?.references as KidsTypes.IImage[])?.reduce(
    (acc, imageEntry) => {
      const name = getNameFromImageEntry(imageEntry) || "";
      return { ...acc, [name]: imageEntry?.fields?.image };
    },
    {} as Record<string, Asset | undefined>
  );

/**
 * Uses last word of the Entry's name to get the Image Entry's associated reading level.
 *
 * @example
 * "Plan Selection Reading Level Legend" -> "legend"
 */
export const getReadingLevelNameFromEntryName: GetReadingLevelNameFromEntryFn =
  (imageEntry: KidsTypes.IImage) => {
    const readingLevelWords = imageEntry?.fields?.name
      ?.trim?.()
      .toLowerCase?.()
      ?.split(" ");
    const readingLevelName = readingLevelWords?.[readingLevelWords?.length - 1];
    return readingLevelName || "";
  };

export interface GetReadingLevelImageSetHoistedArgs {
  readingLevel?: string;
  imagesReadingLevelReferencesHoisted?: HoistFields<KidsTypes.IManyReferencesFields>;
}

export const getImageSetForReadingLevelHoisted = ({
  readingLevel,
  imagesReadingLevelReferencesHoisted,
}: GetReadingLevelImageSetHoistedArgs) => {
  if (!readingLevel || !imagesReadingLevelReferencesHoisted) {
    return null;
  }

  const readingLevelLowerCase = readingLevel.toLowerCase();
  const imageSet = imagesReadingLevelReferencesHoisted?.references?.find(
    (ref) => (ref.slug as string)?.toLowerCase() === readingLevelLowerCase
  ) as HoistFields<KidsTypes.IManyReferencesFields>;

  if (!imageSet) {
    return null;
  }

  const images = imageSet.references?.reduce((acc: ImageProps[], ref) => {
    const contentfulImageFields = ref as HoistFields<KidsTypes.IImage>;

    const widthAsNumber = Number(
      contentfulImageFields.image?.file?.details?.image?.width
    );
    const width = isNaN(widthAsNumber) ? undefined : widthAsNumber / 2; // 2x image provided

    const heightAsNumber = Number(
      contentfulImageFields.image?.file?.details?.image?.height
    );
    const height = isNaN(heightAsNumber) ? undefined : heightAsNumber / 2; // 2x image provided

    const imageProps: ImageProps = {
      src: contentfulImageFields.image?.file.url || "",
      alt: contentfulImageFields.image?.description,
      width,
      height,
    };
    return [...acc, imageProps];
  }, []);

  return images || null;
};
