import { ReactNode } from "react";
import styled from "styled-components";
import analytics from "analytics";
import { useField } from "formik";

import DLSErrorMessage from "components/DLS/DLSErrorMessage";
import GuidedSignUpRadioBoxy from "components/GuidedSignUp/GuidedSignUpRadioBoxy";
import GuidedSignUpRadio from "components/GuidedSignUp/GuidedSignUpRadio";

export enum GuidedSignUpRadioVariant {
  default = "default",
  boxy = "boxy",
}

export type GuidedSignUpRadioGroupOption = {
  checkedBorderColor?: string;
  id: string;
  label: ReactNode;
  subLabel?: string;
  suppressCheckmarkIcon?: boolean;
  value: string;
};

export type GuidedSignUpRadioGroupOptions = GuidedSignUpRadioGroupOption[];

export interface GuidedSignUpRadioGroupProps {
  className?: string;
  options: GuidedSignUpRadioGroupOptions;
  name: string;
  onClick?: (option: GuidedSignUpRadioGroupOption) => void;
  variant?: GuidedSignUpRadioVariant;
}

const RadioGroup = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 0.875em;
  list-style: none;
`;

function GuidedSignUpRadioGroup({
  className = "",
  options,
  name,
  onClick,
  variant = GuidedSignUpRadioVariant.default,
}: GuidedSignUpRadioGroupProps) {
  const [_, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <RadioGroup className={className}>
      {options?.map((option) => (
        <li key={option.id}>
          {variant === GuidedSignUpRadioVariant.boxy && (
            <GuidedSignUpRadioBoxy
              {...option}
              name={name}
              onClick={() => {
                if (typeof option.label === "string") {
                  analytics.trackAmplitudeClickEvent(option.label);
                }
                onClick?.(option);
              }}
            />
          )}
          {variant === GuidedSignUpRadioVariant.default && (
            <GuidedSignUpRadio
              {...option}
              name={name}
              onClick={() => {
                if (typeof option.label === "string") {
                  analytics.trackAmplitudeClickEvent(option.label);
                }
                onClick?.(option);
              }}
            />
          )}
        </li>
      ))}
      {touched && error && <DLSErrorMessage message={error} />}
    </RadioGroup>
  );
}

export default GuidedSignUpRadioGroup;
