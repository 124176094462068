import React from "react";
import styled from "styled-components";
import { FormikHelpers } from "formik";
import {
  PlanSelectionContainer,
  PlanSelectionRadioClassName,
} from "components/PlanSelection/PlanSelectionRadio";
import {
  Image,
  Ribbon,
  colors,
  ImageProps,
  IconName,
} from "@literati/public-ui-shared-react";

/** This is the className that will be pre-pended to all of PlanSelectionForm's inner component classNames */
export const PLAN_SELECTION_FORM_CLASS_BASE = "plan-selection-form";

/** These are all of the internal classNames that are used within the PlanSelectionForm component */
export const PlanSelectionDisplayClassName = {
  BASE: PLAN_SELECTION_FORM_CLASS_BASE,
  FORM: `${PLAN_SELECTION_FORM_CLASS_BASE}-form`,
} as const;

const RibbonText = styled.span`
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.1rem;
  margin: 0 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
`;

const StyledRibbon = styled(Ribbon)`
  margin: auto;
  margin-bottom: -15px;
  z-index: 1;
`;

const BigPrice = styled.span`
  font-size: 3rem;
  font-weight: 800;
`;
const MediumPrice = styled.span`
  font-size: 1.5rem;
  font-weight: 800;
`;
const LittlePrice = styled.span`
  font-size: 0.875rem;
  font-weight: 900;
`;
const Content = styled.div`
  text-align: center;
`;
const Subtitle = styled.div`
  font-size: 0.875rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const defaultImageProps = {
  src: "//images.ctfassets.net/vqtz4cq2w5ts/RpTjPWKr5BwjwdFsMdltI/0f44d418c5123f0f523ad94c91128c1a/LITERATI_04_11_22_Kids_Inside_the_Box_Babies_Toddlers_001__2__1.png",
  width: 175,
  height: 116,
};

const StyledPlanSelectionContainer = styled(PlanSelectionContainer)`
  border-color: #dcdcdc;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.inkBlack};
  .${PlanSelectionRadioClassName.NOTE_TEXT} {
    font-size: 0.75rem;
  }
  .${PlanSelectionRadioClassName.NOTE} {
    background: ${colors.sunrayTint2};
  }
  .${PlanSelectionRadioClassName.NOTE_ICON} {
    path {
      fill: ${colors.publisherOrange};
    }
  }
`;

export type FormikSubmit = (
  values: {
    [x: string]: string;
  },
  formikHelpers: FormikHelpers<{
    [x: string]: string;
  }>
) => void | Promise<any>;

export function PlanSelectionDisplay({
  imageProps,
  ribbonText = "Monthly Book Box",
  subtitle = "buy the ones you like, return the rest for free",
  note = "Book prices guaranteed to match or beat amazon",
  noteIcon = IconName.RAINBOW,
}: {
  imageProps?: ImageProps | undefined;
  ribbonText?: string;
  subtitle?: string;
  note?: string;
  noteIcon?: IconName;
}) {
  imageProps =
    imageProps?.width && imageProps?.height
      ? {
          ...imageProps,
          // Make image size 15% smaller than what's in Contentful
          width: imageProps?.width * 0.85,
          height: imageProps?.height * 0.85,
        }
      : defaultImageProps;
  return (
    <Container>
      <StyledRibbon
        height={28}
        bgColor="periwinkleShade1"
        label={<RibbonText>{ribbonText}</RibbonText>}
        variant="BOTH"
      />
      <StyledPlanSelectionContainer
        note={note}
        noteIcon={noteIcon}
        checked={true}
      >
        <Content>
          <ImageContainer>
            <Image {...imageProps} />
          </ImageContainer>
          <BigPrice>$9</BigPrice>
          <MediumPrice>.95 </MediumPrice>
          <LittlePrice>/ month</LittlePrice>
          <Subtitle>{subtitle}</Subtitle>
        </Content>
        <div></div>
      </StyledPlanSelectionContainer>
    </Container>
  );
}

export default PlanSelectionDisplay;
