import { getDateDifferenceInMonths } from "utils/date";

export interface GetReaderAgeInMonthsArgs {
  birthYear: number;
  birthMonth: number;
  todayFn?: () => Date;
}

/**
 * Gets number of months between today's date and the child's relative birth date.
 */
export function getReaderAgeInMonths({
  birthMonth,
  birthYear,
  todayFn = () => new Date(),
}: GetReaderAgeInMonthsArgs) {
  const today = todayFn();
  // month is 0-indexed
  const relativeBirthDate = new Date(birthYear, birthMonth - 1, 1);

  return getDateDifferenceInMonths(today, relativeBirthDate);
}

export default getReaderAgeInMonths;
