import { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { useField } from "formik";
import { colors } from "@literati/public-ui-shared-react";

const CLASS_BASE = "guided-sign-up-radio-boxy";
export const GuidedSignUpRadioBoxyClassName = {
  BASE: CLASS_BASE,
  LABEL: `${CLASS_BASE}-label`,
  MAIN_LABEL: `${CLASS_BASE}-main-label`,
  SUB_LABEL: `${CLASS_BASE}-sub-label`,
} as const;

interface GuidedSignUpRadioBoxyProps {
  className?: string;
  label: ReactNode;
  subLabel?: ReactNode;
  name: string;
  value: string;
  id: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

const RadioContainer = styled.div<{ $checked: boolean }>`
  background: ${({ $checked }) =>
    $checked ? colors["periwinkleTint5"] : colors.white};
  border: 1px solid
    ${({ $checked }) => ($checked ? "transparent" : colors["newsprint3"])};
  border-radius: 8px;
  color: ${colors.inkBlack};
  cursor: pointer;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1.5rem 1fr;
  line-height: 1.375;
  padding: 0.875rem;
  padding: 1rem 20px;
  place-items: center;
  position: relative;
  transition-property: background, opacity;
  transition: background 175ms ease-in-out;
  width: 100%;
`;

const RadioLabel = styled.label<{ $checked: boolean }>`
  cursor: inherit;
  justify-self: baseline;
`;

const Radio = styled.input`
  position: absolute;
  opacity: 0;
`;

const RadioControl = styled.span<{ $checked: boolean }>`
  position: relative;
  border-radius: 50%;
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  transition: opacity 125ms ease-in-out;
  justify-self: center;

  ${({ $checked }) =>
    $checked
      ? `
          background-color: ${colors.periwinkleWhimsical};
          opacity: 1;
        `
      : `
          border: 1px solid ${colors.warmGray1};
        `}
`;

const RadioIcon = styled.svg`
  margin: auto;
`;

const MainLabel = styled.span`
  display: block;
  font-weight: 700;
  font-size: 1.15rem;
`;

const SubLabel = styled.span`
  display: block;
  font-size: 0.9rem;
  color: ${colors.warmGray4};
`;

function GuidedSignUpRadioBoxy({
  className = "",
  label,
  subLabel,
  name,
  value,
  id,
  onClick,
  ...fieldProps
}: GuidedSignUpRadioBoxyProps) {
  const cls = GuidedSignUpRadioBoxyClassName;

  const [field, , fieldHelpers] = useField(name);
  const checked = field.value === value;

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!checked) {
      fieldHelpers.setValue(value);
    } else {
      fieldHelpers.setValue("");
    }
    onClick?.(e);
  };

  return (
    <RadioContainer
      $checked={checked}
      className={className}
      data-testid={`radio-boxy-${label}`}
      onClick={handleClick}
    >
      <Radio {...field} {...fieldProps} type="radio" defaultChecked={checked} />
      <RadioControl $checked={checked}>
        <RadioIcon
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 1L4.125 7.875L1 4.75"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </RadioIcon>
      </RadioControl>
      <RadioLabel htmlFor={id} $checked={checked} className={cls.LABEL}>
        <div>
          <MainLabel className={cls.MAIN_LABEL}>{label}</MainLabel>
          <SubLabel className={cls.SUB_LABEL}>{subLabel}</SubLabel>
        </div>
      </RadioLabel>
    </RadioContainer>
  );
}

export default GuidedSignUpRadioBoxy;
