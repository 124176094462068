import { shuffleArray } from "utils/arrays";
import cloneDeep from "lodash/cloneDeep";

/**
 * Returns the contentful model with the themes shuffled.
 *
 * @param {Object} contentfulQuizModel
 */
export function shuffleThemes(contentfulQuizModel) {
  let shuffledModel = cloneDeep(contentfulQuizModel);

  const stepIdx = shuffledModel?.quizSteps?.findIndex(
    (qs) => qs.slug === "reader-theme-preference"
  );
  const answers = shuffledModel?.quizSteps?.[stepIdx]?.questions?.[0]?.answers;

  if (!answers) {
    return contentfulQuizModel;
  }

  const shuffledThemes = shuffleArray(answers);
  shuffledModel.quizSteps[stepIdx].questions[0].answers = shuffledThemes;

  return shuffledModel;
}

export default shuffleThemes;
