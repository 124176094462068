import PropTypes from "prop-types";
import styled from "styled-components";
import { useField } from "formik";
import { ReactNode } from "react";

const RadioContainer = styled.div`
  position: relative;
`;

const RadioLabel = styled.label<{
  $checked: boolean;
  $checkedBorderColor: string;
}>`
  position: relative;
  display: grid;
  grid-template-columns: 1.5rem 1fr 1.5rem;
  place-items: center;
  grid-gap: 1rem;
  line-height: 1.375;
  padding: 0.875rem;
  transition: background 175ms ease-in-out;
  transition-property: background, opacity;
  background: ${({ $checked, theme }) =>
    $checked ? "#B3C9FF4D" : theme.colors["white"]};
  border: 1px solid;
  border-color: ${({ $checked, $checkedBorderColor, theme }) =>
    $checked ? $checkedBorderColor : theme.colors["newsprint3"]};
  border-radius: 1.625rem;
  width: 100%;
  color: ${({ $checked, theme }) =>
    $checked ? theme.colors["perriwinkle-dark"] : theme.colors["ink-black"]};
  cursor: pointer;
`;

const Radio = styled.input`
  position: absolute;
  opacity: 0;
`;

const RadioControl = styled.span<{
  $checked: boolean;
  $suppressCheckmarkIcon: boolean;
}>`
  position: relative;
  background-color: ${({ $checked, $suppressCheckmarkIcon, theme }) =>
    $checked && !$suppressCheckmarkIcon
      ? theme.colors["perriwinkle"]
      : "transparent"};
  border-radius: 50%;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;

  transition: opacity 125ms ease-in-out;
  opacity: ${({ $checked }) => ($checked ? "1" : "0")};
`;

const RadioIcon = styled.svg`
  margin: auto;
`;

const VisualLabel = styled.span`
  text-align: center;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  inset: 0;
  background: transparent;
  border-radius: 1.625rem;
  overflow: hidden;
`;

const Tag = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  background: #546ac9;
  color: white;
  font-size: 0.625rem;
  font-weight: 900;
`;

export type Tag = {
  bgColor: string;
  color: string;
  label: ReactNode;
};

export type Tags = [Tag | null, Tag | null];

export interface GuidedSignUpRadioProps {
  checkedBorderColor?: string;
  className?: string;
  id: string;
  label: ReactNode;
  name: string;
  onClick?: () => void;
  suppressCheckmarkIcon?: boolean;
  tags?: Tags;
  value: string;
}

function GuidedSignUpRadio(props: GuidedSignUpRadioProps) {
  const {
    checkedBorderColor = "transparent",
    className,
    label,
    suppressCheckmarkIcon = false,
    tags,
    ...fieldProps
  } = props;
  const { name, value, id } = fieldProps;
  const [field] = useField(name);
  const checked = field.value === value;

  return (
    <RadioContainer className={className}>
      <Radio {...field} {...fieldProps} type="radio" defaultChecked={checked} />
      <RadioLabel
        htmlFor={id}
        $checked={checked}
        $checkedBorderColor={checkedBorderColor}
        data-testid={id}
      >
        {tags ? (
          <Tags>
            {tags.map((tag, idx) => (
              <Tag
                css={`
                  background-color: ${tag?.bgColor || "transparent"};
                  color: ${tag?.color || "transparent"};
                `}
                key={idx}
              >
                {tag?.label || ""}
              </Tag>
            ))}
          </Tags>
        ) : null}
        <RadioControl
          $checked={checked}
          $suppressCheckmarkIcon={suppressCheckmarkIcon}
        >
          {!suppressCheckmarkIcon ? (
            <RadioIcon
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L4.125 7.875L1 4.75"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </RadioIcon>
          ) : null}
        </RadioControl>
        <VisualLabel className="guided-sign-up-radio-label">
          {label}
        </VisualLabel>
      </RadioLabel>
    </RadioContainer>
  );
}

GuidedSignUpRadio.defaultProps = {
  className: "",
};

GuidedSignUpRadio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default GuidedSignUpRadio;
