import { postGuidedSignUpAnswers } from "lib/api/onboarding";

/**
 * Builds payload and posts answers for a guided sign up step.
 *
 * @param {Object} args
 * @param {Function} [args.asyncPostFn]
 * @param {any} args.normalizedAnswers
 * @param {string} args.quizSlug
 * @param {string} args.quizVersion
 * @param {Object} args.step
 * @param {Object} [args.subscription]
 */
async function postAnswers({
  asyncPostFn = postGuidedSignUpAnswers,
  normalizedAnswers,
  quizSlug,
  quizVersion,
  step,
  subscription,
}) {
  if (
    !asyncPostFn ||
    !normalizedAnswers ||
    !quizSlug ||
    (typeof quizVersion !== "number" && !quizVersion) ||
    !step
  ) {
    console.error("postAnswers: missing required information for post");
    return false;
  }

  const submissions = Object.keys(normalizedAnswers).map((slug) => {
    const question = step?.questions?.find(
      (question) => question.slug === slug
    );
    const answer = normalizedAnswers[slug];
    return { question, answer };
  });
  const subscriptionId = subscription?.id;

  return asyncPostFn({
    quizSlug,
    quizVersion,
    subscriptionId,
    submissions,
  });
}

export default postAnswers;
