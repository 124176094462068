import { Dispatch, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { getGuidedSignUpAnswers } from "lib/api/onboarding";
import { GuidedSignUpContext } from "contexts/GuidedSignUpProvider";
import {
  SubscriptionDetailsSchemaCamelCase,
  SubscriptionsContext,
} from "contexts/SubscriptionsProvider";
import { SubscriptionsDispatchContext } from "contexts/SubscriptionsProvider";
import { CheckoutContext } from "contexts/CheckoutProvider";
import getNavigationRoute from "utils/GuidedSignUp/getNavigationRoute";
import getReaderAgeInMonths from "utils/GuidedSignUp/getReaderAgeInMonths";
import { parse } from "url";
import { NonNull } from "types/contentful";
import {
  ContentfulQuizModelHoisted,
  ReadingSkillJsonItem,
  SkipIfFilterFunction,
  SkipIfSet,
  Step,
  UserAnswers,
} from "types/contentful";
import useWindowStorage from "utils/hooks/useWindowStorage";
import { gsuSubmitAnswersDefault } from "components/Step/stepUtil";
import { RelationshipValues } from "components/Step/stepTypes";
import { FormikHelpers } from "formik";
// TODO: Remove budget question experiments when over

export const GuidedSignUpStepType = {
  ACCOUNT_STEP: "accountStep",
  CONFIRMATION_STEP: "confirmationStep",
  PLAN_STEP: "planStep",
  QUIZ_QUESTION: "guidedSignUpStep",
};

export const GuidedSignUpStepSlug = {
  CONFIRMATION: "confirmation",
  CONFIRMATION_FUNNEL: "confirmation-funnel",
  READER_PROFILE: "reader-profile",
  READER_PROFILE_FUNNEL: "reader-profile-funnel",
  READER_GRADE: "reader-grade",
  READER_THEME_PREFERENCE: "reader-theme-preference",
  RELATIONSHIP: "reader-relationship",
  BUDGET: "reader-budget",
  BUDGET_MONTHLY: "reader-budget-monthly",
  BUDGET_PLAN_DETAILS: "budget-plan-details",
  GENDER: "reader-gender",
  FUNNEL_REBASE_PLAN_SELECTION_3: "funnel-rebase-plan-selection-3",
} as const;

// @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
const NavigationType = {
  BACK_FORWARD: "back_forward",
  NAVIGATE: "navigate",
  PRE_RENDER: "prerender",
  RELOAD: "reload",
};

// @TODO: configurations for non-quiz steps
const accountSteps: Step[] = [];
const planSteps: Step[] = [];
const confirmationSteps: Step[] = [];

/**
 * Skips step if CMS configured subscription values meet conditions.
 */
function shouldSkipStepFromSubscription({
  skipIfFilterFunction,
  skipIfSet,
  subscription,
}: {
  skipIfFilterFunction: SkipIfFilterFunction;
  skipIfSet?: SkipIfSet;
  subscription?: SubscriptionDetailsSchemaCamelCase;
}) {
  const isObj = typeof skipIfSet === "object";

  if (!subscription || !skipIfFilterFunction || !isObj) {
    return false;
  }

  type SkipIfSetKey = keyof NonNull<SkipIfSet>;

  return (Object.keys(skipIfSet) as SkipIfSetKey[])?.[skipIfFilterFunction]?.(
    (skipIfKey) => {
      const skipIfValues = skipIfSet[skipIfKey];
      return skipIfValues?.some((value) => value === subscription[skipIfKey]);
    }
  );
}

/**
 * Custom skip logic for reader-grade step.
 */
export function shouldSkipReaderGradeStep({
  subscription,
  userAnswers,
}: {
  subscription?: SubscriptionDetailsSchemaCamelCase;
  userAnswers?: UserAnswers;
}) {
  if (!!subscription) {
    return subscription.estimatedGrade === null;
  }

  // these conditions are likely when another step prior to this one
  // has already answered some questions, but reader-birth-month is not
  // yet defined, because the reader-grade step has not yet been shown
  if (
    !userAnswers ||
    !userAnswers["reader-birth-month"] ||
    !userAnswers["reader-birth-year"]
  ) {
    return false;
  }

  const birthMonth = userAnswers["reader-birth-month"][0]?.value;
  const birthYear = userAnswers["reader-birth-year"][0]?.value;

  /**
   * @todo - (REFACTOR) - this is logical/type bug (revealed by TypeScript)
   *        TypeScript is correct that `birthMonth` and `birthYear` here are of type `string`,
   *        with numbers encoded in their strings. `getReaderAgeInMonths` only works because
   *        implicit type conversion makes "1" - 1 === 0 internally in this function in JavaScript
   */
  const ageInMonths = getReaderAgeInMonths({
    birthMonth: birthMonth as any,
    birthYear: birthYear as any,
  });

  // 4.5 years old
  return ageInMonths === null ? true : ageInMonths < 54;
}

type ActiveVariant = string | undefined;

/** A map of experiments in use for users in the funnel */
export interface GuidedSignUpFunnelUserActiveExperiments {
  [experimentName: string]: ActiveVariant;
}

/**
 * Skips an experimental guided signup step unless the user is in the appropriate variant
 *
 * For branching logical flow chart:
 * @see https://www.figma.com/file/x8BD2YCr92W7ua0eqzZhZY/Skip-Logic-for-Experiments?node-id=0%3A1&t=4KZfckBVboe9GHFK-1
 */
export function shouldSkipTestBasedOnActiveExperiments({
  step,
}: {
  step: Step;
}) {
  const experimentData = step?.skipIf?.experiment;

  // A step is assumed to be part of an experiment if the "experiment" property is present
  // and the experiment property also is populated with some data
  const stepIsPartOfAnExperiment =
    experimentData && Object.keys(experimentData).length > 0;

  // step is not associated with any experiment, so we can show it
  if (!stepIsPartOfAnExperiment) return false;

  return true;
}

export type StoredRelationshipValue = {
  values: RelationshipValues;
  formikHelpers: FormikHelpers<RelationshipValues>;
};

export function shouldSkipRelationshipStep({
  storedRelationshipValue,
}: {
  storedRelationshipValue?: StoredRelationshipValue | null;
}) {
  return Boolean(storedRelationshipValue);
}

/**
 * Determines if a step should be skipped based on user answers.
 * Returns true if any of the possible reasons to skip return true.
 *
 * @note - both user answers and/or subscription values are checked based on CMS defined conditions
 */
function shouldSkipStep({
  step,
  subscription,
  userAnswers,
  contentfulQuizModel,
  storedRelationshipValue,
}: {
  step: Step;
  subscription?: SubscriptionDetailsSchemaCamelCase;
  userAnswers: UserAnswers;
  contentfulQuizModel: any;
  storedRelationshipValue?: StoredRelationshipValue | null;
}) {
  if (step?.slug === "reader-relationship") {
    const shouldSkip = shouldSkipRelationshipStep({ storedRelationshipValue });

    if (shouldSkip) {
      gsuSubmitAnswersDefault({
        contentfulQuizModel,
        formikHelpers: storedRelationshipValue?.formikHelpers as any,
        onSubmit: () => undefined,
        step,
        values: storedRelationshipValue?.values,
      });
    }

    return shouldSkip;
  }

  if (step?.slug === "reader-grade") {
    return shouldSkipReaderGradeStep({ subscription, userAnswers });
  }

  const shouldSkipDueToSubscription = shouldSkipStepFromSubscription({
    skipIfFilterFunction: step?.skipIfFilterFunction,
    skipIfSet: step?.skipIf?.subscription,
    subscription,
  });

  return shouldSkipDueToSubscription;
}

/**
 * Determines if it's possible for a user to step forward in the flow.
 *
 * @note - currently assumes only quiz steps
 */
function shouldIncrementStep({
  step,
  steps,
}: {
  step?: Step | undefined | null;
  steps?: Step[] | undefined | null;
}) {
  const lastStep = steps?.[steps?.length - 1];
  const stepsLength = steps?.length ?? 0;
  return stepsLength > 1 && step?.slug !== lastStep?.slug;
}

/**
 * Determines if it's possible for a user to step backward in the flow.
 *
 * @note - currently assumes only quiz steps
 */
function shouldDecrementStep({
  steps,
  userPath,
}: {
  steps: Step[];
  userPath?: number[] | null;
}) {
  return steps?.length > 1 && userPath && userPath?.length > 1;
}

/**
 * Determines next step in guided sign up flow.
 */
function getNextStepIndex({
  steps,
  subscription,
  userAnswers,
  userPath,
  contentfulQuizModel,
  storedRelationshipValue,
}: {
  steps: Step[];
  subscription?: SubscriptionDetailsSchemaCamelCase;
  userAnswers: UserAnswers;
  userPath?: number[] | null;
  contentfulQuizModel: any;
  storedRelationshipValue?: StoredRelationshipValue | null;
}) {
  const currentStepIndex = userPath?.[userPath?.length - 1] as number;
  const forwardSteps = steps?.slice(currentStepIndex + 1);

  // iterate to find first non-skipped step
  const nextIndex = forwardSteps.findIndex(
    (step) =>
      !shouldSkipStep({
        step,
        subscription,
        userAnswers,
        storedRelationshipValue,
        contentfulQuizModel,
      })
  );

  const numSkips = nextIndex === -1 ? 0 : nextIndex;

  return currentStepIndex + 1 + numSkips;
}

/**
 * Determines completion percentage of quiz steps.
 */
function getQuizProgress({
  steps,
  userPath,
}: {
  steps: Step[];
  userPath?: number[] | null;
}) {
  const currentPathValue = userPath?.[userPath?.length - 1] as number;
  const stepsLength = steps?.length;
  return steps && userPath ? ((currentPathValue + 1) * 100) / stepsLength : 0;
}

/**
 * Derives reading level based on reading level name and reading skills,
 * then sets the reading level in context.
 */
const deriveAndSetReadingLevel =
  ({
    readingSkills,
    setReadingLevel,
  }: {
    readingSkills: ReadingSkillJsonItem[];
    setReadingLevel?: Dispatch<ReadingSkillJsonItem>;
  }) =>
  (readingLevelName: string) => {
    if (!readingLevelName || !readingSkills) {
      console.error(
        "setReadingLevel: `readingLevelName` or `readingSkills` was not provided"
      );
      return;
    }

    const readingLevel = readingSkills?.find(
      (readingSkill) =>
        readingSkill?.name?.toLowerCase() ===
        readingLevelName?.toLocaleLowerCase()
    );

    if (!readingLevel) {
      console.warn("setReadingLevel: `readingLevel` could not be determined");
      return;
    }

    setReadingLevel?.(readingLevel);
  };

/**
 * State machine for guided sign up.
 */
function useGuidedSignUp(args: {
  contentfulQuizModel: ContentfulQuizModelHoisted;
  requiresSubscription?: boolean;
  isExistingUserFlow?: boolean;
}) {
  const [_, { getItem }] = useWindowStorage("sessionStorage");

  const { contentfulQuizModel, requiresSubscription = false } = args || {};

  const [hydratingGuidedSignUp, setHydratingGuidedSignUp] = useState(false);
  const [stateHydrated, setStateHydrated] = useState(false);

  const router = useRouter();

  const { subscription, updateChildInfo } = useContext(SubscriptionsContext);
  const { getSubscriptionBasics } = useContext(SubscriptionsDispatchContext);
  const { updateChildInfo: updateChildInfoCheckout, updateSelectedClub } =
    useContext(CheckoutContext);

  const context = useContext(GuidedSignUpContext);
  const {
    decrementStep: decStep,
    hydrateState,
    incrementStep: incStep,
    quizSlug,
    quizVersion,
    readingLevel,
    readingSkills,
    setContentfulQuizModel,
    setReadingLevel,
    setSteps,
    step,
    steps,
    storage,
    storageReady,
    updateUserAnswers,
    userPath,
  } = context;

  useEffect(() => {
    // TODO: Why not just initialize this to true?
    setHydratingGuidedSignUp(true);
  }, []);

  const getGuidedSignUpAnswersMemo = useMemo(() => {
    if (!quizSlug || quizVersion === null) {
      return;
    }
    const token = (router?.query?.token as string) || undefined;
    const subscriptionId = router?.query?.id
      ? Number(router?.query?.id)
      : undefined;

    return getGuidedSignUpAnswers({
      quizSlug,
      quizVersion: Number(quizVersion),
      subscriptionId,
      token,
    });
  }, [quizSlug, quizVersion]);

  // iniitialize quiz data into context
  useEffect(() => {
    let quizSteps = contentfulQuizModel?.quizSteps;

    quizSteps =
      quizSteps?.filter(
        (step) =>
          !shouldSkipTestBasedOnActiveExperiments({
            step,
          })
      ) ?? [];

    const steps = [
      ...quizSteps,
      ...accountSteps,
      ...planSteps,
      ...confirmationSteps,
    ];

    setSteps?.(steps);
    setContentfulQuizModel?.(contentfulQuizModel);
  }, [contentfulQuizModel?.version]);

  // hydrate quiz from saved data from server and browser
  useEffect(() => {
    (async () => {
      if (!stateHydrated && storageReady) {
        const [lastNavigation] =
          performance?.getEntriesByType?.("navigation") || [];
        const navigationType = (lastNavigation as any)?.type;

        const apiUserAnswers = await getGuidedSignUpAnswersMemo;

        // hydrate context from apis and storage
        if (navigationType !== NavigationType.RELOAD) {
          // take user to first step for non-reload navigation type
          const slug = steps?.[0]?.slug;
          const route = getNavigationRoute({
            contentfulQuizModel,
            preserveTokenQuery: true,
            router,
            slug,
          });
          await router.push(route);

          hydrateState?.({
            step: steps?.[0],
            userAnswers: {
              ...storage?.userAnswers,
              ...(apiUserAnswers as unknown as UserAnswers),
            },
            userPath: [0],
          });
        } else {
          hydrateState?.({
            ...storage,
            userAnswers: {
              ...storage?.userAnswers,
              ...(apiUserAnswers as unknown as UserAnswers),
            },
          });
        }
        setStateHydrated(true);
      }
    })();
  }, [stateHydrated, storageReady]);

  const getSubscription = () => {
    const subId = parseInt(router?.query?.id as string, 10);
    const token = router?.query?.token as string;

    if (subId) {
      return getSubscriptionBasics?.({ subId, token });
    }
  };

  useEffect(() => {
    // TODO: Maybe this should be a server side prop?
    if (requiresSubscription && !subscription) {
      getSubscription();
    }
  }, [router.query, subscription]);

  useEffect(() => {
    const signalHydrationEnd = requiresSubscription
      ? stateHydrated && !!subscription
      : stateHydrated;

    if (signalHydrationEnd) {
      setHydratingGuidedSignUp(false);
    }
  }, [stateHydrated, subscription]);

  useEffect(() => {
    // in order to keep the browser back and forward in sync while providing
    // the best quiz pagination performance, we intercept the route event
    // and increment or decrement the quiz state here
    const eventName = "routeChangeStart";
    const handleStart = (url: string) => {
      const { pathname: querylessUrl } = parse(url, true);
      const trimmedUrl = querylessUrl?.replace(/\/$/, ""); // trim trailing slash
      const newSlug = trimmedUrl?.split?.("/")?.pop();

      const isQuizSlug = Object.values(GuidedSignUpStepSlug)?.find(
        (slug) => slug === newSlug
      );
      if (!isQuizSlug) {
        return;
      }

      const prevSlug = router?.query?.slug;

      if (!newSlug || prevSlug === newSlug) {
        return;
      }

      const prevSlugIndex = steps?.findIndex((step) => step.slug === prevSlug);
      const newSlugIndex = steps?.findIndex((step) => step.slug === newSlug);
      const push = newSlugIndex > prevSlugIndex;

      push ? incStep?.(newSlugIndex) : decStep?.();
    };

    router.events.on(eventName, handleStart);

    return () => {
      router.events.off(eventName, handleStart);
    };
  }, [router, steps]);

  const decrementStep = async () => {
    if (shouldDecrementStep({ steps, userPath })) {
      // delegates state change to routeChangeStart event
      await router.back();
    }
  };

  const incrementStep = async (args: {
    userAnswers: UserAnswers;
    subscription?: SubscriptionDetailsSchemaCamelCase;
    shallow?: boolean;
  }) => {
    const { userAnswers, subscription, shallow = true } = args || {};

    const storedRelationshipValue =
      (getItem("reader-relationship") as StoredRelationshipValue) || null;

    if (shouldIncrementStep({ step, steps })) {
      const nextStepIndex = getNextStepIndex({
        steps,
        subscription,
        userAnswers,
        userPath,
        contentfulQuizModel,
        storedRelationshipValue,
      });

      const slug = steps[nextStepIndex]?.slug;
      const route = getNavigationRoute({
        contentfulQuizModel,
        preserveTokenQuery: false,
        router,
        slug,
      });
      // delegates to routeChangeStart event
      await router.push(route, undefined, { shallow });
    }
  };

  const currentStepIndex = userPath ? userPath?.[userPath?.length - 1] : 0;
  const lastStepIndex = steps ? Math.max(steps?.length - 1, 0) : 0;
  // protect against render when no steps are populated
  const isLastStep =
    Boolean(steps.length) && currentStepIndex === lastStepIndex;

  return {
    context,
    decrementStep,
    getSubscription,
    hydratingGuidedSignUp,
    incrementStep,
    isFirstStep: currentStepIndex === 0,
    isLastStep,
    quizProgress: getQuizProgress({ steps, userPath }),
    quizSlug,
    quizVersion,
    readingLevel,
    readingSkills,
    setReadingLevel: deriveAndSetReadingLevel({
      readingSkills,
      setReadingLevel,
    }),
    storage,
    step,
    subscription,
    updateChildInfo,
    updateChildInfoCheckout,
    updateSelectedClub,
    updateUserAnswers,
  };
}

export default useGuidedSignUp;
