import PropTypes from "prop-types";
import styled from "styled-components";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import GuidedSignUpSelect from "components/GuidedSignUp/GuidedSignUpSelect";
import GuidedSignUpRadioGroup from "components/GuidedSignUp/GuidedSignUpRadioGroup";
import { guidedSignUpReplaceTemplateStrings } from "utils/guidedSignUpTemplating";

/**
 * Gets initial values for the reading level screen.
 *
 * @note priority: storage -> default
 *
 * @param {Object} args
 * @param {import("components/GuidedSignUp/GuidedSignUpQuiz").UserAnswers} args.defaultInitialValues
 * @param {Object} args.storage
 * @param {Object} args.subscription
 */
export const getInitialValuesReadingGrade = ({
  defaultInitialValues,
  storage,
  subscription,
}) => {
  const gradeKey = "reading-level-adjustment-grade";
  const adjustmentKey = "reading-level-adjustment";

  let estimatedGradeNumber;
  if (!isNaN(subscription?.readingLevelGrade)) {
    estimatedGradeNumber = Math.floor(subscription.readingLevelGrade);
  } else {
    if (!isNaN(subscription?.estimatedGrade)) {
      estimatedGradeNumber = Math.floor(subscription.estimatedGrade);
    }
  }

  const estimatedGradeFormValue =
    typeof estimatedGradeNumber === "number"
      ? estimatedGradeNumber < -3
        ? "-3"
        : estimatedGradeNumber?.toString()
      : null;

  const customValues = {
    [gradeKey]:
      storage?.userAnswers?.[gradeKey]?.[0]?.value ??
      (estimatedGradeFormValue || defaultInitialValues[gradeKey]),
    [adjustmentKey]:
      subscription?.readingLevelAdjustment?.toString() ??
      storage?.userAnswers?.[adjustmentKey]?.[0]?.value?.toString() ??
      defaultInitialValues[adjustmentKey],
  };

  return { ...defaultInitialValues, ...customValues };
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 645px;
`;

const Select = styled(GuidedSignUpSelect)`
  margin: 1.5rem 0 0;
`;

const RadioGroupHeading = styled(GuidedSignUpHeading)`
  margin: 2.625rem 0 0;
`;

const RadioGroup = styled(GuidedSignUpRadioGroup)`
  margin: 1.5rem 0 0;
`;

/**
 * Custom layout for the reading grade guided sign up step.
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Object} props.step
 * @param {string} [props.childName]
 */
function GuidedSignUpLayoutReadingGrade({ className = "", step, childName }) {
  const [questionReaderGrade, questionReadingAdjustment] = step?.questions;
  const templateConfigValues = {
    childName,
  };
  const readerGradePrompt = guidedSignUpReplaceTemplateStrings(
    questionReaderGrade?.prompt,
    templateConfigValues
  );
  const readingAdjustmentPrompt = guidedSignUpReplaceTemplateStrings(
    questionReadingAdjustment?.prompt,
    templateConfigValues
  );

  return (
    <Container className={className}>
      <GuidedSignUpHeading
        primaryHeading={readerGradePrompt}
        subheading={questionReaderGrade?.subtext}
      />
      <Select question={questionReaderGrade} />
      <RadioGroupHeading
        primaryHeading={readingAdjustmentPrompt}
        subheading={questionReadingAdjustment?.subtext}
      />
      <RadioGroup
        name={questionReadingAdjustment?.slug}
        options={questionReadingAdjustment?.answers.map((answer) => ({
          id: answer?.slug,
          label: answer?.label,
          value: answer?.value,
        }))}
      />
    </Container>
  );
}

GuidedSignUpLayoutReadingGrade.propTypes = {
  className: PropTypes.string,
  step: PropTypes.shape({
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        answers: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            slug: PropTypes.string,
            value: PropTypes.string,
          })
        ),
        prompt: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
  }),
  childName: PropTypes.string,
};

export default GuidedSignUpLayoutReadingGrade;
