import {
  BulletsProps,
  ColorsKey,
  KidsTypes,
} from "@literati/public-ui-shared-react";
import { HoistFields, Step } from "types/contentful";
import {
  GuidedSignUpPriceInfoProps,
  GuidedSignUpPriceInfoRibbonDefaults,
} from "components/GuidedSignUp/GuidedSignUpPriceInfo";
import {
  GuidedSignUpHowItWorksBulletsPropsDefaults,
  GuidedSignUpHowItWorksProps,
} from "components/GuidedSignUp/GuidedSignUpHowItWorks";
import { GuidedSignUpBoxShippingDetailsProps } from "components/GuidedSignUp/GuidedSignUpBoxShippingDetails";
import {
  GuidedSignUpFaqProps,
  GuidedSignUpFaqQuestionAnswer,
  GuidedSignUpFaqQuestionAnswersDefaults,
} from "components/GuidedSignUp/GuidedSignUpFaq";
import { getImageSetForReadingLevelHoisted } from "utils/readingLevelImageUtils";
import { ReadingLevel } from "lib/api/types";

export function getReadingLevelColorTheme(readingLevel?: ReadingLevel): {
  dark: ColorsKey;
  light: ColorsKey;
} {
  if (!readingLevel) {
    return {
      dark: "frenchBlueShade2",
      light: "frenchBlueTint4",
    };
  }

  switch (readingLevel.name) {
    case "Dreamer":
    case "Seeker":
    case "Stargazer":
    case "Discoverer":
      return {
        dark: "periwinkleShade1",
        light: "periwinkleTint5",
      };
    case "Adventurer":
    case "Navigator":
    case "Aviator":
      return {
        dark: "tealShade3",
        light: "mintTint4",
      };
    case "Trailblazer":
    case "Wizard":
    case "Legend":
      return {
        dark: "palmGreenShade1",
        light: "palmGreenTint3",
      };
    case "Scout":
    case "Tinkerer":
    case "Ranger":
    case "Voyager":
    default:
      return {
        dark: "frenchBlueShade2",
        light: "frenchBlueTint4",
      };
  }
}

/**
 * Manual type for shape of json subtree in `options` step field from Contentful
 * @note - fallbacks are `undefined` purposefully so components use defaults
 */
export interface SectionRibbonPriceOptions {
  price?: string;
  cadence?: string;
  ribbonLabel?: string;
  subtext?: string;
}

export function getPropsAdapterSectionRibbonPrice(
  sectionRibbonPrice?: SectionRibbonPriceOptions
) {
  const [priceWholeDollarAmount, priceCents] = sectionRibbonPrice?.price?.split(
    "."
  ) || [undefined, undefined];

  const priceInfoProps: GuidedSignUpPriceInfoProps = {
    priceWholeDollarAmount,
    priceCents,
    priceCadence: sectionRibbonPrice?.cadence || undefined,
    subtext: sectionRibbonPrice?.subtext || undefined,
    ribbonProps: {
      ...GuidedSignUpPriceInfoRibbonDefaults,
      label:
        sectionRibbonPrice?.ribbonLabel ||
        GuidedSignUpPriceInfoRibbonDefaults.label,
    },
  };

  return priceInfoProps;
}

/**
 * Manual type for shape of json subtree in `options` step field from Contentful
 * @note - fallbacks are `undefined` purposefully so components use defaults
 */
export interface SectionHowItWorksOptions {
  primaryHeading?: string;
  bullets?: string[];
}

export function getPropsAdapterSectionHowItWorks(
  sectionHowItWorks?: SectionHowItWorksOptions
) {
  const bulletsItemProps: BulletsProps["items"] =
    sectionHowItWorks?.bullets?.map((textContent, i) => ({
      key: `gsu-how-works-bullet-${i}`,
      textContent,
    })) || [];

  const bulletsProps: BulletsProps = {
    ...GuidedSignUpHowItWorksBulletsPropsDefaults,
    items: bulletsItemProps,
  };

  const howItWorksProps: GuidedSignUpHowItWorksProps = {
    heading: sectionHowItWorks?.primaryHeading || undefined,
    bulletsProps: bulletsItemProps.length ? bulletsProps : undefined,
  };

  return howItWorksProps;
}

/**
 * Manual type for shape of json subtree in `options` step field from Contentful
 * @note - fallbacks are `undefined` purposefully so components use defaults
 */
export interface SectionBoxShippingDetailsOptions {
  primaryHeading?: string;
  shippingCopy?: string;
  subtext?: string;
}

export function getPropsAdapterSectionBoxShippingDetails(
  sectionBoxShippingDetails?: SectionBoxShippingDetailsOptions
) {
  const { primaryHeading, shippingCopy, subtext } =
    sectionBoxShippingDetails || {};

  const boxShippingDetailsProps: GuidedSignUpBoxShippingDetailsProps = {
    heading: primaryHeading || undefined,
    subtext: subtext || undefined,
    shippingCopy: shippingCopy || undefined,
  };

  return boxShippingDetailsProps;
}

/**
 * Manual type for shape of json subtree in `options` step field from Contentful
 * @note - fallbacks are `undefined` purposefully so components use defaults
 */
export interface SectionFaqOptions {
  primaryHeading?: string;
  questionAnswers?: { question?: string; answer?: string }[];
}

export function getPropsAdapterSectionFaq(sectionFaq?: SectionFaqOptions) {
  const questionAnswers = sectionFaq?.questionAnswers
    ?.map((qa) => {
      const isValidItem = qa?.question && qa?.answer;
      const item = isValidItem ? qa : null;
      return item;
    })
    .filter(Boolean) as GuidedSignUpFaqQuestionAnswer[];

  const faqProps: GuidedSignUpFaqProps = {
    heading: sectionFaq?.primaryHeading || undefined,
    questionAnswers: questionAnswers || GuidedSignUpFaqQuestionAnswersDefaults,
  };

  return faqProps;
}

export interface GuidedSignUpLayoutFunnelRebaseOptions {
  sectionRibbonPrice: SectionRibbonPriceOptions;
  sectionHowItWorks: SectionHowItWorksOptions;
  sectionBoxShippingDetails: SectionBoxShippingDetailsOptions;
  sectionFaq: SectionFaqOptions;
}

export interface GetPropsAdapterGuidedSignUpFunnelRebaseArgs {
  readingLevel: ReadingLevel;
  step?: Step<
    GuidedSignUpLayoutFunnelRebaseOptions,
    HoistFields<KidsTypes.IManyReferencesFields>
  >;
}

export function getPropsAdapterGuidedSignUpFunnelRebase({
  readingLevel,
  step,
}: GetPropsAdapterGuidedSignUpFunnelRebaseArgs) {
  if (!step) {
    return null;
  }

  const options = step?.options as GuidedSignUpLayoutFunnelRebaseOptions;
  const content = step
    ?.content?.[0] as HoistFields<KidsTypes.IManyReferencesFields>;

  const [
    howItWorksImageProps,
    boxShippingDetailsImage1,
    boxShippingDetailsImage2,
  ] =
    getImageSetForReadingLevelHoisted({
      readingLevel: readingLevel?.name,
      imagesReadingLevelReferencesHoisted: content,
    }) || [];

  const colors = getReadingLevelColorTheme(readingLevel);

  const sectionRibbonPrice = options?.sectionRibbonPrice;
  const priceInfoProps = {
    ...getPropsAdapterSectionRibbonPrice(sectionRibbonPrice),
    ribbonProps: {
      ...GuidedSignUpPriceInfoRibbonDefaults,
      bgColor: colors.dark,
    },
  };

  const sectionHowItWorks = options?.sectionHowItWorks;
  const howItWorksProps = {
    ...getPropsAdapterSectionHowItWorks(sectionHowItWorks),
    bgColor: colors.light,
    imageProps: howItWorksImageProps,
  };

  const sectionBoxShippingDetails = options?.sectionBoxShippingDetails;
  const boxShippingDetailsProps: GuidedSignUpBoxShippingDetailsProps = {
    ...getPropsAdapterSectionBoxShippingDetails(sectionBoxShippingDetails),
    images: [boxShippingDetailsImage1, boxShippingDetailsImage2],
  };

  const sectionFaq = options?.sectionFaq;
  const faqProps = getPropsAdapterSectionFaq(sectionFaq);

  return {
    priceInfoProps,
    howItWorksProps,
    boxShippingDetailsProps,
    faqProps,
  };
}
