import styled from "styled-components";
import analytics from "analytics";
import { getBirthYears } from "utils/date";
import { GuidedSignUpStepSlug } from "utils/hooks/useGuidedSignUp";
import GuidedSignUpSelect from "components/GuidedSignUp/GuidedSignUpSelect";
import DLSTextInput from "components/DLS/DLSTextInput";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import { guidedSignUpReplaceTemplateStrings } from "utils/guidedSignUpTemplating";
import { SubscriptionDetailsSchemaCamelCase } from "contexts/SubscriptionsProvider";
import { ImageProps } from "@literati/public-ui-shared-react";

/**
 * Combines app derived years and placeholder with required API metadata.
 */
export function getYearAnswers() {
  const placeholder = {
    label: "Please Select",
    preferenceId: "reader_birth_year",
    slug: "reader-birth-year-select-placeholder",
    value: "",
  };

  const years = getBirthYears().map((year) => ({
    label: year.toString(),
    preferenceId: "reader_birth_year",
    slug: `reader-birth-year-${year}`,
    value: year.toString(),
  }));

  return [placeholder, ...years];
}

/**
 * Derives initial values for the post purchase flow.
 *
 * @param {Object} args
 * @param {Object} args.subscription
 */
export const getInitialValuesProfileSubscription = ({
  subscription,
}: {
  subscription: SubscriptionDetailsSchemaCamelCase;
}) => {
  const name = subscription?.childName;
  const birthMonth = subscription?.childBirthMonth;
  const birthYear = subscription?.childBirthYear?.toString();
  const fixedAge = subscription?.preserveReadingLevel;

  return { name, birthMonth, birthYear, fixedAge };
};

/**
 * Derives initial values for the funnel flow.
 *
 * @param {Object} args
 * @param {Object} args.keys
 * @param {Object} args.storage
 */
export const getInitialValuesProfileFunnel = ({
  keys,
  storage,
}: {
  keys: Record<string, string>;
  storage: any;
}) => {
  const userAnswers = storage?.userAnswers;

  const name = userAnswers?.[keys?.name]?.[0]?.value;
  const birthMonth = userAnswers?.[keys?.birthMonth]?.[0]?.value;
  const birthYear = userAnswers?.[keys?.birthYear]?.[0]?.value;
  const fixedAge = userAnswers?.[keys?.fixedAge]?.[0]?.value;

  return { name, birthMonth, birthYear, fixedAge };
};

/**
 * Gets initial values for the profile screen.
 *
 * @note priority: api answers -> default
 *
 * @param {Object} args
 * @param {import("components/GuidedSignUp/GuidedSignUpQuiz").UserAnswers} args.defaultInitialValues
 * @param {Boolean} args.isGuidedSignUpFunnel
 * @param {Object} args.storage
 * @param {Object} args.subscription
 */
export const getInitialValuesProfile = ({
  defaultInitialValues,
  isGuidedSignUpFunnel,
  storage,
  subscription,
}: {
  defaultInitialValues: any;
  isGuidedSignUpFunnel: boolean;
  storage: any;
  subscription: SubscriptionDetailsSchemaCamelCase;
}) => {
  const keys = {
    name: "reader-name",
    birthMonth: "reader-birth-month",
    birthYear: "reader-birth-year",
    fixedAge: "reader-fixed-age",
  };

  const values = isGuidedSignUpFunnel
    ? getInitialValuesProfileFunnel({ keys, storage })
    : getInitialValuesProfileSubscription({ subscription });

  const { name, birthMonth, birthYear, fixedAge } = keys;
  const customValues = {
    [name]: values?.name || defaultInitialValues[name],
    [birthMonth]: values?.birthMonth || defaultInitialValues[birthMonth],
    [birthYear]: values?.birthYear || defaultInitialValues[birthYear],
    [fixedAge]: values?.fixedAge || defaultInitialValues[fixedAge],
  };

  return { ...defaultInitialValues, ...customValues };
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 434px;
`;

const PageHeading = styled(GuidedSignUpHeading)`
  align-self: center;
`;

const SectionHeading = styled.p`
  margin: 2rem 0 0;
  font-size: 0.875rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors["ink-black"]};
`;

const Input = styled(DLSTextInput)`
  margin: 1rem 0 0;
`;

const Select = styled(GuidedSignUpSelect)`
  margin: 1rem 0 0;
`;

const FixedAge = styled.div`
  font-size: 0.875rem;
`;

const FixedAgeDescription = styled.p`
  margin: 0.5rem 0 0;
  color: #72716f; // @TODO: use approved gray
`;

export type GSUStepReaderProfile = {
  image?: ImageProps;
  prompt: string;
  questions: {
    answers: {
      label?: string;
      preferenceId: string;
      slug: string;
      value?: string;
    }[];
    label: string;
    slug: string;
    subtext?: string;
  }[];
  sectionHeaders: string[];
  slug: string;
  subtext?: string;
};

/**
 * Allows user to validate and/or change reader profile data.
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Object} props.step
 * @param {Object} [props.childName]
 */
function GuidedSignUpLayoutReaderProfile({
  className = "",
  step,
  childName,
}: {
  className?: string;
  step: GSUStepReaderProfile;
  childName?: string;
}) {
  const [nameSectionHeading, birthDateSectionHeading, fixedAgeSectionHeading] =
    step?.sectionHeaders || [];

  const [
    questionName,
    questionBirthMonth,
    questionBirthYear,
    questionFixedAge,
  ] = step?.questions || [];

  // answers are left empty in the CMS for birth year and derived here
  if (questionBirthYear) {
    questionBirthYear.answers = getYearAnswers();
  }

  return (
    <Container className={className}>
      <PageHeading
        imageConfig={step?.image}
        primaryHeading={guidedSignUpReplaceTemplateStrings(step?.prompt, {
          childName,
        })}
        subheading={step?.subtext}
      />
      <SectionHeading>{nameSectionHeading}</SectionHeading>
      <Input
        label={questionName?.label}
        name={questionName?.slug}
        placeholder={questionName?.label}
        onClick={() => analytics.trackAmplitudeClickEvent(questionName?.label)}
      />
      <SectionHeading>{birthDateSectionHeading}</SectionHeading>
      <Select question={questionBirthMonth} />
      <Select question={questionBirthYear} />
      {step?.slug !== GuidedSignUpStepSlug.READER_PROFILE_FUNNEL && (
        <FixedAge>
          <SectionHeading>{fixedAgeSectionHeading}</SectionHeading>
          <FixedAgeDescription>{questionFixedAge?.subtext}</FixedAgeDescription>
        </FixedAge>
      )}
    </Container>
  );
}

export default GuidedSignUpLayoutReaderProfile;
