import PropTypes from "prop-types";
import styled from "styled-components";
import analytics from "analytics";
import {
  DLSAppleAppStoreButton,
  DLSGooglePlayStoreButton,
} from "components/DLS/DLSAppStoreButtons";
import { GuidedSignUpStepSlug } from "utils/hooks/useGuidedSignUp";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import { usePlatform } from "contexts/PlatformProvider";
import GuidedSignUpReadingLevelCard from "components/GuidedSignUp/GuidedSignUpReadingLevelCard";
import { useEffect } from "react";
import { guidedSignUpReplaceTemplateStrings } from "utils/guidedSignUpTemplating";
import LoadingAnimation from "@/atoms/account/LoadingAnimation";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin: 0 -1.5rem;
  background: ${({ theme }) => theme.colors["white"]};
`;

const Heading = styled(GuidedSignUpHeading)`
  margin: 0 1.5rem;
`;

const H2 = styled.h2`
  font-size: 1.125rem;
  font-weight: 800;
  text-align: center;
`;

const Claim = styled.li`
  margin: ${({ $first }) => !$first && `1rem 0 0`};
  font-size: 0.875rem;
  font-weight: 500;
  list-style: inside;
`;

const DownloadContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem 1rem;
  width: 100%;
  background: ${({ theme }) => theme.colors["mint3"]};
`;

const DownloadH2 = styled(H2)`
  margin: 3rem 0 0;
  color: ${({ theme }) => theme.colors["ink-black"]};
  text-align: left;
`;

const ButtonFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0 0;
`;

const AppleButton = styled(DLSAppleAppStoreButton)`
  width: 145px;
  height: 45px;
`;

const GoogleButton = styled(DLSGooglePlayStoreButton)`
  width: 146px;
  height: 47px;
`;

const BodyText = styled.p`
  font-family: avenir;
  font-size: 0.875rem;
`;

const StyledHR = styled.hr`
  border: 1px solid #aae0d5;
  width: calc(100% - 2.5rem);
  max-width: 300px;
  margin: 1rem auto 1.5rem;
`;

const NextStepsSection = styled.section`
  width: calc(100% - 2.5rem);
  max-width: 600px;
  padding: 1rem;
  padding: 0;
  margin: 2rem 0;
`;

const NextStepsHeading = styled.h3`
  font-family: avenir;
  font-weight: 800;
  font-size: 1rem;
  margin-bottom: 0.8rem;
  text-align: center;
`;

/**
 * Displays the confirmation details of the Guided Sign up flow.
 *
 * @component
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Function} [props.onMount]
 * @param {Object} props.readingLevel
 * @param {boolean} [props.postingQuizCompletion]
 * @param {Object} props.step
 * @param {Object} [props.storage]
 * @param {Object} [props.childName]
 * @param {Object} props.readingLevelBadgesNoText
 */
function GuidedSignUpLayoutConfirmation({
  className = "",
  onMount,
  readingLevel,
  postingQuizCompletion,
  step,
  childName,
  readingLevelBadgesNoText,
}) {
  const { image: confirmationFallbackImage, prompt } = step;
  const { isNativeApp } = usePlatform();
  const msCopy = step?.options?.copy?.manageSubscription;
  const nextStepsCopy = step?.options?.copy?.nextSteps;
  const appStoreSectionTitle = step?.options?.copy?.appStore?.title;
  const cardCopy = step?.options?.copy?.card;
  const readingLevelName = readingLevel?.name?.toLowerCase?.();
  const readingLevelImage =
    readingLevelBadgesNoText?.[readingLevelName]?.fields ||
    confirmationFallbackImage;
  const templateConfigValues = { childName, readingLevelName };

  useEffect(() => {
    onMount?.();
  }, []);

  return (
    <Container className={className}>
      <Heading
        primaryHeading={guidedSignUpReplaceTemplateStrings(
          prompt,
          templateConfigValues
        )}
      />
      {postingQuizCompletion ? (
        <LoadingAnimation />
      ) : (
        <GuidedSignUpReadingLevelCard
          readingLevel={readingLevel}
          cardCopy={cardCopy}
          readingLevelImage={readingLevelImage}
        />
      )}

      {nextStepsCopy && (
        <NextStepsSection>
          <NextStepsHeading>{nextStepsCopy?.title}</NextStepsHeading>
          <BodyText>
            {guidedSignUpReplaceTemplateStrings(
              nextStepsCopy?.description,
              templateConfigValues
            )}
          </BodyText>
        </NextStepsSection>
      )}

      {step.slug !== GuidedSignUpStepSlug.CONFIRMATION_FUNNEL && (
        <DownloadContainer>
          {!isNativeApp && (
            <>
              <DownloadH2>{appStoreSectionTitle}</DownloadH2>
              <ButtonFlexContainer>
                <AppleButton
                  onClick={() =>
                    analytics.trackAmplitudeClickEvent("iOS Store")
                  }
                />
                <GoogleButton
                  onClick={() =>
                    analytics.trackAmplitudeClickEvent("Android Store")
                  }
                />
              </ButtonFlexContainer>
            </>
          )}

          {/* Separator is only needed when both sections are defined */}
          {!isNativeApp && msCopy && <StyledHR />}

          {msCopy && (
            <ul>
              <Claim $first>
                {guidedSignUpReplaceTemplateStrings(
                  msCopy?.valueClaims?.[0],
                  templateConfigValues
                )}
              </Claim>
              <Claim>{msCopy?.valueClaims?.[1]}</Claim>
              <Claim>{msCopy?.valueClaims?.[2]}</Claim>
            </ul>
          )}
        </DownloadContainer>
      )}
    </Container>
  );
}

GuidedSignUpLayoutConfirmation.defaultProps = {
  step: {},
};

GuidedSignUpLayoutConfirmation.propTypes = {
  className: PropTypes.string,
  onMount: PropTypes.func,
  postingQuizCompletion: PropTypes.bool,
  readingLevel: PropTypes.object,
  step: PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      height: PropTypes.number,
      src: PropTypes.string,
      width: PropTypes.number,
    }),
    prompt: PropTypes.string,
  }),
  childName: PropTypes.string,
};

export default GuidedSignUpLayoutConfirmation;
