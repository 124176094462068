import { getPossessiveName } from "utils/grammar";

/** Replaces childName template strings (like `$childName` and `$childName-possessive`) from Contentful with actual, formatted value */
export const guidedSignUpReplaceChildName = (rawString = "", rawChildName = ""): string => {
  const sanitizedChildName = rawChildName || "your reader";
  const possessiveChildName = rawChildName
    ? getPossessiveName(rawChildName)
    : "your reader's";

  return rawString
    .replace("$name-possessive", possessiveChildName)
    .replace("$name", sanitizedChildName);
};

/** Replaces reading level template strings from Contentful with the actual, formatted reading level childName */
export const guidedSignUpReplaceReadingLevelName = (
  rawString = "",
  rawReadingLevelName = ""
): string => {
  const sanitizedReadingLevelName =
    rawReadingLevelName || "your reader's reading level";

  return rawString.replace("$level", sanitizedReadingLevelName);
};

/** Fills all available template variables in a string with values */
export const guidedSignUpReplaceTemplateStrings = (
  rawString: string = "",
  {
    childName,
    readingLevelName,
  }: {
    childName?: string;
    readingLevelName?: string;
  } = {}
) => {
  let result = rawString;

  result = guidedSignUpReplaceChildName(result, childName);
  result = guidedSignUpReplaceReadingLevelName(result, readingLevelName);

  return result;
};
