import React from "react";
import styled from "styled-components";
import { Step, GuidedSignUpQuestionFieldsHoisted } from "types/contentful";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import GuidedSignUpRadioGroup, {
  GuidedSignUpRadioGroupOptions,
  GuidedSignUpRadioGroupProps,
  GuidedSignUpRadioVariant,
} from "components/GuidedSignUp/GuidedSignUpRadioGroup";
import { GuidedSignUpStorage } from "contexts/GuidedSignUpProvider";
import { guidedSignUpReplaceTemplateStrings } from "utils/guidedSignUpTemplating";

export const getInitialValuesLayoutRadio = ({
  questionKey,
  defaultInitialValues,
  storage,
}: {
  questionKey: string;
  defaultInitialValues?: any;
  storage: GuidedSignUpStorage;
}) => {
  const relationshipValue =
    storage?.userAnswers?.[questionKey]?.[0]?.value ||
    defaultInitialValues?.[questionKey];

  return { ...defaultInitialValues, [questionKey]: relationshipValue };
};

/** This is the className that will be pre-pended to all of GuidedSignUpLayoutRadio's inner component classNames */
export const GUIDED_SIGN_UP_LAYOUT_RADIO_CLASS_BASE =
  "guided-sign-up-layout-radio";

/** These are all of the internal classNames that are used within the GuidedSignUpLayoutRadio component */
export const GuidedSignUpLayoutRadioClassName = {
  BASE: GUIDED_SIGN_UP_LAYOUT_RADIO_CLASS_BASE,
} as const;

const StyledGuidedSignUpHeading = styled(GuidedSignUpHeading)`
  margin: 0 auto 1.5rem;
`;

const StyledGuidedSignUpRadioGroup = styled(GuidedSignUpRadioGroup)<{
  $subheadingMissing: boolean;
}>`
  margin: ${({ $subheadingMissing }) =>
    $subheadingMissing ? "2rem 0 1.5rem" : "1.5rem 0"};
`;

const StyledDiv = styled.div``;

export type GuidedSignUpLayoutRadioProps = {
  className?: string;
  step: Step;
  childName?: string;
  readingLevelName?: string;
};

export const getGuidedSignUpRadioGroupProps = (
  question: GuidedSignUpQuestionFieldsHoisted
): GuidedSignUpRadioGroupProps | null => {
  const fieldType = question.fieldType;
  const variant =
    fieldType === "radio"
      ? GuidedSignUpRadioVariant.default
      : fieldType === "radio_boxy"
      ? GuidedSignUpRadioVariant.boxy
      : null;

  const name = question.slug;

  const transformedOptions =
    question.answers?.map((answer) => {
      const { id, label, subLabel, value } = answer;

      if (!label || !value) {
        // signal incomplete data for option
        return null;
      }

      return {
        id,
        label,
        subLabel,
        value,
      };
    }) || null;

  const isValidOptions =
    transformedOptions && !transformedOptions?.some((opt) => opt === null);

  if (!isValidOptions || !variant || !name) {
    return null;
  }

  // @note - TS can't create correct type from filter
  const options = transformedOptions.filter(
    Boolean
  ) as GuidedSignUpRadioGroupOptions;

  return {
    options,
    name,
    variant,
  };
};

export const GuidedSignUpLayoutRadio = ({
  className = "",
  childName = "",
  readingLevelName = "",
  step,
}: GuidedSignUpLayoutRadioProps) => {
  const question = step?.questions?.[0];

  if (!question) {
    return null;
  }

  const radioGroupProps = getGuidedSignUpRadioGroupProps(question);

  if (!radioGroupProps) {
    return null;
  }

  const templatingArgs = {
    childName,
    readingLevelName,
  };
  const primaryHeading = guidedSignUpReplaceTemplateStrings(
    step.prompt,
    templatingArgs
  );
  const subheading = guidedSignUpReplaceTemplateStrings(
    step.subtext,
    templatingArgs
  );

  const subheadingMissing = !subheading;

  return (
    <StyledDiv
      className={`${GuidedSignUpLayoutRadioClassName.BASE} ${className}`}
    >
      <StyledGuidedSignUpHeading
        primaryHeading={primaryHeading}
        subheading={subheading}
      />
      {radioGroupProps && (
        <StyledGuidedSignUpRadioGroup
          $subheadingMissing={subheadingMissing}
          {...radioGroupProps}
        />
      )}
    </StyledDiv>
  );
};

export default GuidedSignUpLayoutRadio;
