import styled, { css } from "styled-components";
import {
  KidsTypes,
  PageSectionClassName,
} from "@literati/public-ui-shared-react";
import { ReadingLevel } from "lib/api/types";
import { HoistFields, Step } from "types/contentful";
import {
  getPropsAdapterGuidedSignUpFunnelRebase,
  GuidedSignUpLayoutFunnelRebaseOptions,
} from "components/GuidedSignUp/GuidedSignUpFunnelRebaseAdapter";
import GuidedSignUpBoxShippingDetails from "components/GuidedSignUp/GuidedSignUpBoxShippingDetails";
import GuidedSignUpFaq from "components/GuidedSignUp/GuidedSignUpFaq";
import GuidedSignUpHeading from "components/GuidedSignUp/GuidedSignUpHeading";
import GuidedSignUpHowItWorks from "components/GuidedSignUp/GuidedSignUpHowItWorks";
import GuidedSignUpPriceInfo from "components/GuidedSignUp/GuidedSignUpPriceInfo";

export interface GuidedSignUpLayoutFunnelRebaseProps {
  readingLevel: ReadingLevel;
  step?: Step<
    GuidedSignUpLayoutFunnelRebaseOptions,
    HoistFields<KidsTypes.IManyReferencesFields>
  >;
}

const sharedWidthConstraint = css`
  width: 100%;
  max-width: 404px;
`;

const StyledGuidedSignUpHeading = styled(GuidedSignUpHeading)`
  margin: 0 1.5rem;
`;

const StyledGuidedSignUpPriceInfo = styled(GuidedSignUpPriceInfo)`
  margin: 1rem 0 0;
  ${sharedWidthConstraint}
`;

const StyledGuidedSignUpHowItWorks = styled(GuidedSignUpHowItWorks)`
  margin: 2rem 0 0;
  ${sharedWidthConstraint}
`;

const StyledGuidedSignUpBoxShippingDetails = styled(
  GuidedSignUpBoxShippingDetails
)`
  .${PageSectionClassName.SUBTEXT} {
    ${sharedWidthConstraint}
  }
`;

const StyledGuidedSignUpFaq = styled(GuidedSignUpFaq)`
  /** This adjusts the FAQ section for small view ports in the least impactful way to other layout styles */
  margin: 0 -1.5rem -2.75rem;

  ${({ theme }) => theme.media.sm} {
    margin: 0;
  }
`;

export function GuidedSignUpLayoutFunnelRebase({
  readingLevel,
  step,
}: GuidedSignUpLayoutFunnelRebaseProps) {
  const primaryHeading = step?.prompt || "Your Book Club Plan";

  const { priceInfoProps, howItWorksProps, boxShippingDetailsProps, faqProps } =
    getPropsAdapterGuidedSignUpFunnelRebase({ readingLevel, step }) || {};

  return (
    <>
      <StyledGuidedSignUpHeading primaryHeading={primaryHeading} />
      <StyledGuidedSignUpPriceInfo {...priceInfoProps} />
      <StyledGuidedSignUpHowItWorks {...howItWorksProps} />
      <StyledGuidedSignUpBoxShippingDetails {...boxShippingDetailsProps} />
      <StyledGuidedSignUpFaq {...faqProps} />
    </>
  );
}

export default GuidedSignUpLayoutFunnelRebase;
