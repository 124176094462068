import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { v4 as uuidV4 } from "uuid";
import {
  buttonStyles,
  colors,
  ColorsKey,
  Icon,
  IconName,
  Theme,
} from "@literati/public-ui-shared-react";
import Collapsible, { CollapsibleProps } from "react-collapsible";
import analytics from "analytics";

/** This is the className that will be pre-pended to all of GuidedSignUpCollapsible's inner component classNames */
export const GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE =
  "guided-sign-up-collapsible";

/** These are all of the internal classNames that are used within the GuidedSignUpCollapsible component */
export const GuidedSignUpCollapsibleClassName = {
  BASE: GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE,
  CHILDREN: `${GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE}-children`,
  HEADING: `${GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE}-heading`,
  ICON: `${GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE}-icon`,
  ICON_WRAPPER: `${GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE}-icon-wrapper`,
  TRIGGER: `${GUIDED_SIGN_UP_COLLAPSIBLE_CLASS_BASE}-trigger`,
} as const;

const fontSize = css`
  font-size: 0.875rem;
`;

const CollapsibleTriggerButton = styled.button<{ $bgColor: ColorsKey }>`
  ${buttonStyles.buttonCommonFocusStyles}
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ $bgColor }) => colors[$bgColor]};
  outline: none;
  border-radius: 5px;
  width: 100%;
`;

export const headingStyles = css`
  ${fontSize}
  font-weight: 900;
  text-align: left;
  padding: 0 2rem 0 0;
`;

const Heading = styled.h2`
  ${headingStyles}
`;

const IconRotationWrapper = styled.div`
  transform: rotate(90deg);
  /** icon is pushed off too the right, so this adjusts back left. Because the icon has been rotated, we must target padding-top */
  padding: 0.5rem 0 0 0;
`;

const StyledIcon = styled(Icon)<{ $isOpen: boolean }>`
  transition: transform 400ms;
  transform: rotateY(${({ $isOpen }) => $isOpen && "180deg"});
`;

const CollapsibleContent = styled.div<{ $bgColor: ColorsKey }>`
  ${fontSize}
  padding: 0.75rem 4rem 0 0;
  background-color: ${({ $bgColor }) => colors[$bgColor]};
`;

export interface GuidedSignUpCollapsibleProps
  extends Omit<CollapsibleProps, "trigger"> {
  bgColor?: ColorsKey;
  children: ReactNode;
  className?: string;
  initialIsOpen?: boolean;
  heading: ReactNode;
  /** Omit original property and make optional for our purposes of optionally allowing an override. Type matches underlying react-collapsible prop. */
  trigger?: string | React.ReactElement<any>;
}

export const GuidedSignUpCollapsible = ({
  bgColor = "transparent",
  children,
  className = "",
  initialIsOpen = false,
  heading,
  ...collapsibleOverrides
}: GuidedSignUpCollapsibleProps) => {
  const [open, setOpen] = useState(initialIsOpen);

  const id = `guided-sign-up-collapsible-${uuidV4()}`;

  const handleClick = () => {
    analytics.trackAmplitudeClickEvent(heading as string, "Accordion Trigger");
  };

  return (
    <Theme>
      <Collapsible
        aria-label={open ? "close" : "open"}
        className={`${GuidedSignUpCollapsibleClassName.BASE} ${className}`}
        onClosing={() => setOpen(false)}
        onOpening={() => setOpen(true)}
        open={open}
        overflowWhenOpen={"visible"}
        transitionTime={200}
        trigger={
          <CollapsibleTriggerButton
            aria-controls={id}
            aria-expanded={open}
            $bgColor={bgColor}
            className={GuidedSignUpCollapsibleClassName.TRIGGER}
            onClick={handleClick}
            type="button"
          >
            <Heading className={GuidedSignUpCollapsibleClassName.HEADING}>
              {heading}
            </Heading>
            <IconRotationWrapper
              className={GuidedSignUpCollapsibleClassName.ICON_WRAPPER}
            >
              <StyledIcon
                className={GuidedSignUpCollapsibleClassName.ICON}
                name={IconName.CHEVRON_RIGHT}
                $isOpen={open}
              />
            </IconRotationWrapper>
          </CollapsibleTriggerButton>
        }
        {...collapsibleOverrides}
      >
        <CollapsibleContent
          $bgColor={bgColor}
          className={GuidedSignUpCollapsibleClassName.CHILDREN}
          id={id}
        >
          {children}
        </CollapsibleContent>
      </Collapsible>
    </Theme>
  );
};

export default GuidedSignUpCollapsible;
